import React from "react"
import {Container, Row, Col} from "reactstrap"
import TileContainer from "../containers/TitleContainer"
import "./app.css"
import invisiBreak from "../assets/pattern-3.svg"
import facebook from '../assets/f_logo_RGB-Grey_58.png'
const ContactUs = () => {
return(

    <Container fluid>
            <TileContainer title={"Contact Us"}/>
            <Row className="row-m-20">
                <Col md={6} className="remove-padding col-m-b-20">
                    <div className="textContainer">
                        You can contact the White Hart by email, phone or by sending us a facebook message.
                        <br/> 
                        <br/>
                        Alternatively to discuss your booking needs, why not pop in and have a chat?
                    </div>
                </Col>
                <Col md={6} className="remove-padding">
                    <div className="textContainer">
                        Email: <a href="mailto:info@whiteharttearoom.co.uk" style={{color:"#83868b"}}>info@whiteharttearoom.co.uk</a><br/>
                        Telephone: <tel>01538 372122</tel><br/>
                        Facebook: <a href="https://www.facebook.com/whitehartleek" target="_blank" style={{color:"black"}}>
                    <img src={facebook} style={{maxWidth:50}}/>/whitehartleek
                    </a>
                    </div>
                </Col>
            </Row>
            <Row className="row-m-20">
                <Col className="remove-padding">
                    <div>
                        <img src={invisiBreak} className="center" style={{ width: "100%", height: "50%", borderRadius: 5, opacity: 0.5 }} />
                    </div>
                </Col>
            </Row>
    </Container>
)
}

export default ContactUs