import React from "react"
import { Container, Row, Col } from "reactstrap"
import "./app.css"
import doggo from "../assets/IMG_3606.JPG"
import breakline from "../assets/break.PNG";
import invisiBreak from "../assets/pattern-3.svg"
import modernTeaRoom from "../assets/modernTeaRoom.JPG"
import TileContainer from "../containers/TitleContainer"
import menu from "../assets/THE WHITEHART MENU Feb 2024.pdf"
const Tearoom = () => {
    return (

        <Container fluid>
            
    <Container fluid>
            <TileContainer title={"The Tearoom"}/>
    </Container>
            <Row className="row-m-20">
                <Col md={6} className="col-m-b-20 remove-padding">
                    <img src={modernTeaRoom} className="image-wh shadow-image" style={{ maxHeight: 357 }} />
                </Col>
                <Col className="textContainer" md={6}>

                    <p>The Tearoom is open from 8.30am, 7 days a week and offers a wide menu featuring breakfasts (served all day) homemade main meals, sandwiches and light bites. We have a children’s menu and provide table top toys to keep the little ones happy.</p>

                    <p>We also have a full Vegan menu with a good range of options and the majority of the main menu can be suited to Gluten Free diets.</p>

                    <p>Click <b><a href={menu} target="_blank" style={{color:"black", fontSize:20}}>HERE</a></b> to see our menus.</p>
                </Col>
            </Row>
            <Row className="row-m-20">
                <Col className="remove-padding">
                    <div>
                        <img src={invisiBreak} className="center" style={{ width: "100%", height: "50%", borderRadius: 5, opacity: 0.5 }} />
                    </div>
                </Col>
            </Row>
            <Row className="row-m-20">
                <Col md={6} className="remove-padding">
                    <div className="textContainer col-m-b-20" >
                        <p>We love our dog visitors and are VERY dog friendly. We even have a special “Dog Menu” with all proceeds donated to our local rehoming charity. Some of our B&B rooms are also dog friendly</p>
                    </div>
                </Col>
                <Col md={6} className="col-m-b-20 remove-padding">
                    <img src={doggo} className="image-wh shadow-image" style={{ maxHeight: 357 }} />
                </Col>
            </Row>
            <Row className="row-m-20">
                <Col className="remove-padding">
                    <div>
                        <img src={breakline} className="center shadow-image" style={{ width: "98%", height: "50%", borderRadius: 5, opacity: 0.5 }} />
                    </div>
                </Col>
            </Row>

        </Container>
    )
}

export default Tearoom