import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, FormGroup, Input, Button, Label, Spinner, Progress } from "reactstrap"
import "../app.css"
import TitleContainer from "../../containers/TitleContainer"
import { firestore } from "../../firebase";
import moment from "moment";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ReactTable from 'react-table-v6'
import 'react-table-v6/react-table.css'
import FileUploader from 'react-firebase-file-uploader';
import firebase from "../../firebase.js"
import noImage from "../../assets/noImage.jpg"
import 'bootstrap/dist/css/bootstrap.min.css';




const AdminEvent = (props) => {
  const scrollToRef = () => window.scroll(0,200)   
  const [loadingEvents, setLoadevents] = useState(true);
  const [eventsArray, setEventsArray] = useState([]);
  const [value, setDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [eventDescription, setEventDescription] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [docId, setDocId] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [imageUrl, setImageUrl] = useState('')
  const [avatar, setAvatar] = useState('')
  const [showImageUpload, setShowImageUpload] = useState(false)
  const [addImageButton, setAddImageButton] = useState(false)
  const [readOnly, setReadOnly] = useState(false)
  useEffect(() => {
    const fetchData = async () => {
      if(props.user.userType !== "readWrite"){
        setReadOnly(true)
      }
      const events = firestore.collection('events')
      const doc = await events.orderBy('eventDate', 'desc').get().then((snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEventsArray(data)
      }).then((snapshot) => {
        setLoadevents(false)
      });
    }
    fetchData();
  }, [])

  const showImageUploadTransfer = () => {
    setShowImageUpload(true)
   setAddImageButton(true)}
  const getData = async () => {
    if(props.user.userType !== "readWrite"){
      setReadOnly(true)
    }
    const events = firestore.collection('events')
    const doc = await events.orderBy('eventDate', 'desc').get().then((snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEventsArray(data)
    }).then((snapshot) => {
      setLoadevents(false)
    });
  }

  const displayImageUpload = () => {
    if(showImageUpload){
      return(
        <Row form className="row-m-20">
          <Col md={6}> <Progress value={progress}/>
        <Label for="avatar" style={{marginRight:10, marginTop:10}}>Add Picture</Label>
      <FileUploader
                
                className="uploader"
                accept="image/*"
                name="avatar"
                capture="environment"
                filename={eventTitle + "-photo"}
                storageRef={firebase.storage().ref("images")}
                onUploadStart={handleUploadStart}
                onUploadError={handleUploadError}
                onUploadSuccess={handleUploadSuccess}
                onProgress={handleProgress}
              />
              <Button disabled={readOnly} onClick={(e) => {cancelImageUpload()}}>Cancel</Button>
              {displayImage()}
              </Col>
      </Row>
      )
    }
  }

  const displayImage = () => {
    if(imageUrl.length > 0)
    {
      return(
        <img src={imageUrl} style={{maxHeight:150}}/>
      )
    }
  }
  const cancelImageUpload = () => {
    setAddImageButton(false);
    setImageUrl("");
    setShowImageUpload(false) 

  }
  const addEvent = async (e) => {
    e.preventDefault();

    if(docId != ''){
      firestore.collection('events').doc(docId).update({
        eventTitle:eventTitle,
        eventDescription:eventDescription,
        eventDate: value,
        eventToDate: toDate,
        active:true,
        eventUrl : imageUrl
      }).then((e) => {
        getEventData();
        setEventDescription('')
        setEventTitle('')
        setDocId('')
        setProgress(0)
        setShowImageUpload(false)
        setAddImageButton(false)
        setImageUrl('')
        setDate(new Date())
        setToDate(new Date())

      })

      return;
    }


    
    firestore.collection("events").add({
        eventDescription : eventDescription,
        eventTitle : eventTitle,
        createDate : new Date(),
        eventDate : value,
        eventToDate : toDate,  
        active : true
    }).then( async () => {
        const events = firestore.collection('events')
      const doc = await events.orderBy('active', 'desc').get().then((snapshot) => {
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setEventsArray(data)
    })})
  }
  const onChangeHandler = (event) => {
    const { name, value } = event.currentTarget;
    if(name === 'eventDescription'){
        setEventDescription(value)
    }
    if(name === 'eventTitle'){
      setEventTitle(value)
    }
};

  const rowStatusToString = (bool) => {
    if(bool)
    {
        return "Active"
    }
    else{
        return "Inactive"
    }
}
  

  const columns = React.useMemo(
    () => [
      {
        Header: 'Events',
        columns: [
          {
            Header: 'Event Title',
            accessor: 'eventTitle',
          },
          {
            Header: 'Status',
            id:'active',
            accessor: d=> rowStatusToString(d.active),
          }
        ],
      }
    ],
    []
  )

  const getEventData = async () => {
    const events = firestore.collection('events')
    const doc = await events.orderBy('active', 'desc').get().then((snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEventsArray(data)
    }).then((snapshot) => {
      setLoadevents(false)
    });
  }
  const setRowToInactive = (index) =>{
    var docId = eventsArray[index].id;
    firestore.collection('events').doc(docId).update({active:false}).then((e) => {
    getEventData();
  })
}

const editEvent = (e, index) => {
  var docId = eventsArray[index].id;
  setDocId(docId);
  setEventDescription(eventsArray[index].eventDescription)
  setEventTitle(eventsArray[index].eventTitle)
  setDate(new Date(eventsArray[index].eventDate.seconds * 1000))
  setToDate(new Date(eventsArray[index].eventToDate.seconds * 1000))
  setAddImageButton(false)
  setShowImageUpload(true)
  if(typeof eventsArray[index].imageUrl !== "undefined")
  {
    setImageUrl(eventsArray[index].imageUrl)
  }
  
  scrollToRef()
  
}

const deleteEvent = (e, index) => {
  var docId = eventsArray[index].id;
  if(window.confirm("Are you sure you want to delete this event?")){
    console.log("deleting event!")
    firestore.collection("events").doc(docId).delete().then(() => {
        getData()
    })
}

}

const setRowToActive = (index) => {
  var docId = eventsArray[index].id;
  firestore.collection('events').doc(docId).update({active:true}).then((e) => {
    getEventData();
})
}


  const button = (rowStatus, rowIndex) => {
    if(rowStatus == "Active"){
      return(
        <Button disabled={readOnly} onClick={() => setRowToInactive(rowIndex)}>Make Inactive</Button>
      )
    }
    else{
      return(
        <Button disabled={readOnly} onClick={() => setRowToActive(rowIndex)}>Make Active</Button>
      )
    }
  }

  const displayEventImage = (row) => {
    if(typeof row.imageUrl !== "undefined")
    {
      return(
        <img src={row.imageUrl} style={{maxHeight:250}}/>
      )
    }
    else{
      return(
        <img src={noImage} style={{maxheight:220}}/>
      )
    }
  }

  const displayEvents = () => {
    if (loadingEvents) {
      return (
        <div style={{ textAlign: "center" }}>
          loading events...<br /><br />
          <Spinner />
        </div>
      )
    }
    else {
      return (
        <ReactTable
        data={eventsArray}
        columns={columns}
        defaultPageSize={10}
        collapseOnDataChange={false}

        SubComponent={row => {
          var dateString = new Date(row.row._original.eventDate.seconds * 1000).toString()
          return (
            
            <div>
              <div style={{paddingLeft:10}}>
             <b>Promotion Text:</b> {row.row.eventTitle}
             </div>
             <div style={{paddingLeft:10}}>
             <b>Event Description:</b> {row.row._original.eventDescription}
             </div>
             <div style={{paddingLeft:10, paddingBottom:10}}>
          <b>Event Date</b> {moment(dateString).format("ddd DD/M/YYYY")}
             </div>
             <div>
              {displayEventImage(row.row)}
             </div>
             <div style={{paddingLeft:10, paddingBottom:10}}>
               {button(row.row.active, row.index)}
             </div>
             <div style={{paddingLeft:10, paddingBottom:10}}>
                <Button disabled={readOnly} onClick={(e) => {editEvent(e, row.index)}}>Edit</Button>
             </div>
             <div style={{paddingLeft:10, paddingBottom:10}}>
                <Button disabled={readOnly} onClick={(e)=> {deleteEvent(e, row.index)}}>Delete</Button>
             </div>
            </div>
          );
        }}
      />
      )
    }
  }
  const handleUploadStart = () => {setIsUploading(true); setProgress(0)}
  const handleProgress = progress => setProgress(progress);
  const handleUploadError = async (error) => {
    this.setState({ isUploading: false });
    const errorMessage = firestore.collection('error_log');
    await errorMessage.add({
      timestamp: Date.now(),
      error
    })
  };
  const handleUploadSuccess = filename => {
    setAvatar(filename);
    setProgress(100);
    setIsUploading(false);
    firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then(url => {setImageUrl(url);})
  };
 const cancelEditAndSetToBlanks = () => {
  setEventDescription('')
  setEventTitle('')
  setDocId('')
  setDate(new Date())
 }
  const cancelEdit = () => {
    if(docId !== ""){
      return(
        <Button disabled={readOnly} style={{marginTop:10}}onClick={() => {cancelEditAndSetToBlanks()}}>Cancel Edit</Button>
      )
    }
  }

 const setFromDate = (from) => {
   setToDate(from)
   setDate(from)
 }

  return (
    <Container fluid>
      <TitleContainer title={"Events"} />
      <Row>
        <Col>
          <h3>Create An Event</h3>
        </Col>
      </Row>
      <Form>
        <Row form className="row-m-20">
          <Col md={6}>
            <FormGroup>
              <Label for="eventTitle">Event Title</Label>
              <Input onChange={(event) => onChangeHandler(event)} type="text" value ={eventTitle} name="eventTitle" id="eventTitle" />
            </FormGroup>
          </Col>
          </Row>
          <Row form className="row-m-20">
          <Col md={6}>
            <FormGroup>
              <Label for="eventDescription">Event Description</Label>
              <Input onChange={(event) => onChangeHandler(event)} type="textarea" rows={6} value = {eventDescription} name="eventDescription" id="eventDescription" />
            </FormGroup>
          </Col>
        </Row>
        <Button disabled={readOnly} hidden={addImageButton} onClick={(e) => showImageUploadTransfer()} style={{marginBottom:10}}>Add Image To Event</Button>
        {displayImageUpload()}
        <Row form className="row-m-20">
          <Col md={6}>
            <Label>From:</Label>
          <Calendar
            onChange={setFromDate}
            value={value}
          />
          </Col>
          
          <Col md={6}>
          <Label>To:</Label>
            <Calendar
             onChange={setToDate}
             value={toDate}
             />
          </Col>
        </Row>
        <Row form className="row-m-20">
          <Col>
            <FormGroup>
  <Button disabled={readOnly} onClick={(e) => addEvent(e)}>{(docId === '') ? "Add Event" : "Update Event"}</Button><br/>
  {cancelEdit()}
            </FormGroup>
            
          </Col>
        </Row>
      </Form>
      <Row>
        <Col>
          <h3>Current Events:</h3>
        </Col>

      </Row>
      <Row>
        <Col>
          {displayEvents()}</Col>
      </Row>
    </Container>
  )
}

export default AdminEvent
