import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyAyAo2egxgRYTq8I5ZYZVO85QH7q8LtnlE",
  authDomain: "dev-whitehart.firebaseapp.com",
  databaseURL: "https://dev-whitehart.firebaseio.com",
  projectId: "dev-whitehart",
  storageBucket: "dev-whitehart.appspot.com",
  messagingSenderId: "142385880355",
  appId: "1:142385880355:web:684cd88b84c9dd85f04096",
  measurementId: "G-9N9RLE8V8W"
};
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  export const auth = firebase.auth();
  export const firestore = firebase.firestore();
  export const fireStoreage = firebase.storage();

  export const generateUserDocument = async (user, additionalData) => {
    if (!user) return;
    const userRef = firestore.doc(`users/${user.uid}`);
    const snapshot = await userRef.get();
    if (!snapshot.exists) {
      const { email, displayName, photoURL } = user;
      try {
        await userRef.set({
          displayName,
          email,
          photoURL,
          ...additionalData
        });
      } catch (error) {
        console.error("Error creating user document", error);
      }
    }
    return getUserDocument(user.uid);
  };
  const getUserDocument = async uid => {
    if (!uid) return null;
    try {
      const userDocument = await firestore.doc(`users/${uid}`).get();
      return {
        uid,
        ...userDocument.data()
      };
    } catch (error) {
      console.error("Error fetching user", error);
    }
  };

export default firebase
  