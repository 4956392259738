import React from "react"
import {Container, Row, Col} from "reactstrap"
import TileContainer from "../containers/TitleContainer"
import TeaRoomCarousel from "./TeaRoomCarousel"
const Gallery = () => {
return(

    <Container fluid>
            <TileContainer title={"Gallery"}/>
            <Row className="row-m-20">
            <Col  className="remove-padding" md={{size: 8, offset: 2}}>
                <div className="textContainer">
                    Please enjoy these pictures of the tearoom over the years
            </div>
            </Col>
            </Row>
            <Row className="row-m-20">
            <Col  className="remove-padding" md={{size: 8, offset: 2}}>
                <div className="textContainer">
            <TeaRoomCarousel/>
            </div>
            </Col>
            </Row>
    </Container>
)
}

export default Gallery