import React, {useState, useEffect} from "react"
import { Container, Row, Col, Button } from "reactstrap";
import "../app.css"
import TitleContainer from "../../containers/TitleContainer"
import { navigate } from "@reach/router"
import Switch from '@material-ui/core/Switch';
import { firestore } from "../../firebase";

const Admin = (props) => {
    const [state, setState] = useState({
        checkedA: true,
        checkedB: true
    });
    const [lockdownArray, setLockdownArray] = useState([]);
    const [christmasArray, setChristmasArray] = useState([]);
    const [docId, setDocId] = useState("");
    const [xmasDocId, setXmasDocId] = useState("");
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked })
        console.log(docId)
        console.log(event.target.checked)
        firestore.collection('lockdown').doc(docId).update({
            lockdown: event.target.checked
          })
    };

    const handleXmasChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked })
        console.log(xmasDocId)
        console.log(event.target.checked)
        firestore.collection('christmas').doc(xmasDocId).update({
            christmas: event.target.checked
          })
    };

    useEffect(() => {
        const fetchData = async () => {
          const lockdownValue = firestore.collection('lockdown')
          const doc = await lockdownValue.get().then((snapshot) => {
            const data = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setState({checkedA: data[0].lockdown})
            setDocId(data[0].id)
            setLockdownArray(data)


          }).then((snapshot) => {
            
  
  
            
          });

          
          const christmasValue = firestore.collection('christmas')
          const doc2 = await christmasValue.get().then((snapshot) => {
            const data = snapshot.docs.map((doc2) => ({
              id: doc2.id,
              ...doc2.data(),
            }));
           
            setState({checkedB: data[0].christmas})
            setXmasDocId(data[0].id)
            setChristmasArray(data)}).then(() => { 
               
            })
        }
        fetchData();
      }, [])

    return (
        <Container fluid>
            <TitleContainer title={"Admin Home"} />
            <Row className="row-m-20">
                <Col md={{ size: 6, offset: 3 }} className="col-m-b-20 remove-padding">
                    <div className="textContainer" style={{ textAlign: "center" }}>
                        Welcome to White Hart Tea Room Admin Center {props.user.displayName},<br /> please select an option:<br />
                        <Button onClick={() => { navigate("/admin-events") }} style={{ margin: 10 }}>Create/Edit an Event</Button><br />
                        <Button onClick={() => { navigate("/admin-bookings") }} style={{ margin: 10 }}>B&B booking</Button><br />
                        <Button onClick={() => { navigate("/admin-promotions") }} style={{ margin: 10 }}>Promotions</Button><br />
                        <Button onClick={() => { navigate("/admin-createUser") }} style={{ margin: 10 }}>Create Admin User</Button>
                    </div>
                </Col>
            </Row>
            <Row className="row-m-20">
                <Col md={{ size: 6, offset: 3 }} className="col-m-b-20 remove-padding">
                <div className="textContainer" style={{ textAlign: "center" }}>
                   Lockdown: <Switch
                        checked={state.checkedA}
                        onChange={handleChange}
                        name="checkedA"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    </div>
                </Col>
            </Row>
            <Row className="row-m-20">
                <Col md={{ size: 6, offset: 3 }} className="col-m-b-20 remove-padding">
                <div className="textContainer" style={{ textAlign: "center" }}>
                   Christmas Popup and Menu: <Switch
                        checked={state.checkedB}
                        onChange={handleXmasChange}
                        name="checkedB"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                    />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Admin
