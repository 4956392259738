import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, Input, Button, Label, Spinner } from "reactstrap"
import "../app.css"
import TitleContainer from "../../containers/TitleContainer"
import { firestore } from "../../firebase";
import moment from "moment";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ReactTable from 'react-table-v6'
import 'react-table-v6/react-table.css'

const AdminPromotion = (props) => {

    /* #region  States  */
    const scrollToRef = () => window.scroll(0,200)   
    const [loadingPromotions, setLoadPromotions] = useState(true);
    const [promotionsArray, setpromotionsArray] = useState([]);
    const [labelText, setLabelText] = useState("");
    const [startDate, setStatDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [docId, setDocId] = useState('');
    const [readOnly, setReadOnly] = useState(false)
    /* #endregion */



    /* #region  functions */
   
    useEffect(() => {
        const fetchData = async () => {
          if(props.user.userType !== "readWrite"){
            setReadOnly(true)
          }
          const events = firestore.collection('promotions')
          const doc = await events.orderBy('rowStatus', 'desc').get().then((snapshot) => {
            const data = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setpromotionsArray(data)
          }).then((snapshot) => {
            setLoadPromotions(false)
          });
        }
        fetchData();
      }, [])

    const onChangeHandler = (event) => {
        const { name, value } = event.currentTarget;
        if(name === 'labelText'){
            setLabelText(value)
        }
    };

    const submitPromotion =  async (e) => {
        e.preventDefault();

      if(docId != ''){
        firestore.collection('promotions').doc(docId).update({
          promotionText:labelText,
          startDate:startDate,
          endDate: endDate
        }).then((e) => {
          getPromotionData();
          setLabelText('');
          setStatDate(new Date());
          setEndDate(new Date());
          setDocId('')
        })
      }

        firestore.collection("promotions").add({
            promotionText : labelText,
            startDate : startDate,
            endDate : endDate,
            rowStatus : true
        }).then( async () => {
            const events = firestore.collection('promotions')
          const doc = await events.orderBy('rowStatus', 'desc').get().then((snapshot) => {
            const data = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setpromotionsArray(data)
        })})
    }

    const rowStatusToString = (bool) => {
        if(bool)
        {
            return "Active"
        }
        else{
            return "Inactive"
        }
    }

    const columns = React.useMemo(
        () => [
          {
            Header: 'Current Promotions',
            columns: [
              {
                Header: 'Promotion Title',
                accessor: 'promotionText',
              },
              {
                Header: 'Active',
                id:'rowStatus',
                accessor: d=> rowStatusToString(d.rowStatus),
              },
              
            ],
          }
        ],
        []
      )


const setRowToInactive = (index) =>{
    var docId = promotionsArray[index].id;
    firestore.collection('promotions').doc(docId).update({rowStatus:false}).then((e) => {
    getPromotionData();
  })
}

const setRowToActive = (index) => {
  var docId = promotionsArray[index].id;
  firestore.collection('promotions').doc(docId).update({rowStatus:true}).then((e) => {
  getPromotionData();
})
}

const button = (rowStatus, rowIndex) => {
  if(rowStatus == "Active"){
    return(
      <Button disabled={readOnly} onClick={() => setRowToInactive(rowIndex)}>Make Inactive</Button>
    )
  }
  else{
    return(
      <Button disabled={readOnly} onClick={() => setRowToActive(rowIndex)}>Make Active</Button>
    )
  }
}

const editPromotion = (e, index) => {
  var docId = promotionsArray[index].id;
  setDocId(docId)
  setLabelText(promotionsArray[index].promotionText)
  setStatDate(new Date(promotionsArray[index].startDate.seconds * 1000))
  setEndDate(new Date(promotionsArray[index].endDate.seconds * 1000))
  scrollToRef()
}

const getPromotionData = async () => {
  const events = firestore.collection('promotions')
  const doc = await events.orderBy('rowStatus', 'desc').get().then((snapshot) => {
    const data = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setpromotionsArray(data)
  }).then((snapshot) => {
    setLoadPromotions(false)
  });
}

  const displayPromotions = () => {
    if (loadingPromotions) {
      console.log("loading table")
      return (
        <div style={{ textAlign: "center" }}>
          loading promotions...<br /><br />
          <Spinner />
        </div>
      )
    }
    else {
        console.log("Got data attempting to load table")
        console.log("here's the promoArray!" + promotionsArray)
      return (
        <ReactTable
        data={promotionsArray}
        columns={columns}
        defaultPageSize={10}
        SubComponent={row => {
          return (
            <div>
              <div style={{paddingLeft:10}}>
             <b>Promotion Text:</b> {row.row.promotionText}
             </div>
             <div style={{paddingLeft:10}}>
             <b>Start Date:</b> {moment(row.row.startDate).format("ddd d/M/YYYY")}
             </div>
             <div style={{paddingLeft:10}}>
             <b>End Date:</b> {moment(row.row.endDate).format("ddd d/M/YYYY")}
             </div>
             <div style={{paddingLeft:10, paddingBottom:10}}>
               {button(row.row.rowStatus, row.index)}
             </div>
             <div style={{paddingLeft:10, paddingBottom:10}}>
                <Button disabled={readOnly} onClick={(e) => {editPromotion(e, row.index)}}>Edit</Button>
             </div>
            </div>
          );
        }}
      />
      )
    }
  }

    /* #endregion */

    /* #region  HTML */

    return (
        <Container fluid>
            <TitleContainer title={"Promotions"} />
            <Form>
            <Row className="row-m-20" form>
                <Col className="remove-padding" md={6}>
                    <Label for="labelText">Promotional Text</Label>
                    <Input type="text" onChange={(event) => onChangeHandler(event)} 
                    value={labelText}
                    name="labelText" id="labelText"/>
                </Col>
            </Row>
            <Row className="row-m-20" form>
                <Col className="remove-padding" md={6}>
                    <Label for="startDate">StartDate</Label>
                    <Calendar
                        onChange={setStatDate}
                        value={startDate}
                    />
                </Col>
                <Col className="remove-padding" md={6}>
                    <Label for="endDate">EndDate</Label>
                    <Calendar
                        onChange={setEndDate}
                        value={endDate}
                    />
                </Col>
            </Row>
            <Row from className="row-m-20">
                <Col className="remove-padding" md={6}>
                <Button disabled={readOnly} onClick={(e) => submitPromotion(e)}>{(docId === '') ? "Add Promotion" : "Update Promotion"}</Button><br/>
                </Col>
            </Row>
            </Form>
            <Row>
                <Col md={12}>
                {displayPromotions()}
                </Col>
            </Row>
        </Container>
    )
    /* #endregion */
}

export default AdminPromotion
