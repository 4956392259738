import React from "react"
import { Row, Col } from "reactstrap"
import xmasMenu from "../../assets/xmas-menu-2024.pdf"
import santa from "../../assets/santa.jpg"

const Christmas = () => {
    

    return (
<Row className="row-m-20">
                
                <Col md={12} className="col-m-b-20 remove-padding">
                    <div className="textContainer" style={{textAlign: 'center'}}>
                        <p style={{fontSize: '30px'}}><b>Christmas 2024</b></p>
                        <p>Please find our amazing Christmas 2024 menu and details of how to book <a href={xmasMenu}>HERE</a></p>
                        <div>
                        {/* <img className="image-wh shadow-image" src={santa} /> */}
                    </div>
                </div>
                </Col>
                
            </Row>
       
    )
}

export default Christmas
