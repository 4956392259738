import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form, FormGroup, Input, Button, Label, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import TileContainer from "../containers/TitleContainer"
import invisiBreak from "../assets/pattern-3.svg"
import "./app.css";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { firestore } from "../firebase";
import AccommoCarousel from "./AccomoCarousel"
import AccomoRoomCarousel from "./AccomoRoomCarousel"
import { navigate } from "@reach/router";
import room1_1 from "../assets/room1/room1_1.JPG"
import room1_2 from "../assets/room1/room1_2.JPG"
import room1_3 from "../assets/room1/room1_3.JPG"
import room2_1 from "../assets/room2/room2_1.JPG"
import room2_2 from "../assets/room2/room2_2.JPG"
import room2_3 from "../assets/room2/room2_3.JPG"
import room3_1 from "../assets/room3/room3_1.JPG"
import room3_2 from "../assets/room3/room3_2.JPG"
import room3_3 from "../assets/room3/room3_3.JPG"
import room4_1 from "../assets/room4/room4_1.JPG"
import room4_2 from "../assets/room4/room4_2.JPG"
import room4_3 from "../assets/room4/room4_3.JPG"


const Accommodation = () => {
    const [bookFrom, onChange] = useState();
    const [bookTo, setBookTo] = useState(new Date());
    const [lockdownArray, setLockdownArray] = useState()
    const [lockdownArrayDev, setLockdownArrayDev] = useState(false)
    const [numberOfAdults, setNumberOfAdults] = useState()
    const [numberOfChildren, setNumberOfChildren] = useState()
    const [bringingDogs, setDogs] = useState(false)
    const [dogs, setDogsValue] = useState(2);
    const [travelCot, setTravelCot] = useState(false)
    const [numberOfNights, setNumberOfNights] = useState(1)
    const [singleOccupancy, setSingleOccupancy] = useState(false)
    const [checkingAvailability, setCheckingAvailability] = useState(false)
    const [room1Availability, setRoom1] = useState(false)
    const [room2Availability, setRoom2] = useState(false)
    const [room3Availability, setRoom3] = useState(false)
    const [room4Availability, setRoom4] = useState(false)
    const [room5Availability, setRoom5] = useState(false)
    const [room6Availability, setRoom6] = useState(false)
    const [room7Availability, setRoom7] = useState(false)
    const [room8Availability, setRoom8] = useState(false)
    const [initialRoomData, setInitialRoomData] = useState([])
    const [haveAllData, setHaveAllData] = useState(false)
    const [modal, setModal] = useState(false);
    const [roomData, setRoomData] = useState([{
        RoomNumber: "",
        Picture1: "",
        Picture2: "",
        Picture3: "",
        RoomInfo:""
    }])

    const toggle = () => setModal(!modal);

    const openModel = (roomNumber) => {
        var picture1;
        var picture2;
        var picture3;
        var roomInfo;

        switch(roomNumber) {
        case 1:
            picture1 = room1_1;
            picture2 = room1_2;
            picture3 = room1_3;
            break
        case 2:
            picture1 = room2_1;
            picture2 = room2_2;
            picture3 = room2_3;
            break;
        case 3:
            picture1 = room3_1;
            picture2 = room3_2;
            picture3 = room3_3;
            break;
        case 4:
            picture1 = room4_1;
            picture2 = room4_2;
            picture3 = room4_3;
            break;
        }

        switch(roomNumber){
            case 1:
                roomInfo = "Room one, first floor, standard ensuite room with double bed, front facing."
                break;
            case 2:
                roomInfo = "Room two, first floor, standard ensuite room with double bed, front facing."
                break;
            case 3:
                roomInfo = "Room Three- first floor, superior en suite room with superking or twin beds, a double sofa bed and seating area, front facing."
                break;
            case 4:
                roomInfo  = "Room Four first floor, superior en suite room with superking or twin bed, a double sofa bed, rear facing."
                break;
        }

        var roomData = [
            {
                RoomNumber: roomNumber,
                Picture1: picture1 ,
                Picture2: picture2,
                Picture3: picture3,
                RoomInfo: roomInfo
            }
        ]
        setRoomData(roomData)
        setModal(true)
    }


    const CalendarDisplay = () => {
        return (
            <Calendar
                onChange={onChange}
                value={bookFrom}
                minDate={new Date()}
            />
        )
    }
    useEffect(() => {
        const fetchData = async () => {
            const lockdown = firestore.collection('lockdown')
            const doc = await lockdown.get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setLockdownArray(data[0].lockdown)
            })
        }

        fetchData();

    }, [])
    const onChangeHandler = (event) => {
        const { name, value } = event.currentTarget;

        if (name === 'numberOfAdults') {
            setNumberOfAdults(value);
        }
        else if (name === 'numberOfChildren') {
            setNumberOfChildren(value);
        }
        else if (name === 'dogs') {
            setDogsValue(value)
            if (value == "1") {
                setDogs(true);
            }
            else {
                setDogs(false)
            }
        }
        else if (name === 'travelCot') {
            if (value === 'Yes')
                setTravelCot(true);
        }
        else if (name === 'numberOfNights') {
            setNumberOfNights(value)

        }
        else if (name === 'singleOccupancy'){
            setSingleOccupancy(currentIsOpen => !currentIsOpen)
        }
    };

    const Room1Results = () =>{
        if(room1Availability)
        {
            return(
                <Row className="row-m-20">
                    <Col className="col-m-b-20 remove-padding">
                        <div className="textContainer">
                        Room 1 single double room £58 dogs welcome<br/>
                        A travel cot fits in all rooms and is free of charge.<br/>
                        <Button onClick={() => openModel(1)} style={{marginRight:10}}>Room Info</Button><Button onClick={(event) => {validateAndBook(event, "Room 1")}}>Book Now</Button>
                        </div>
                    </Col>
                </Row>
            )
        }
    }
    const Room2Results = () =>{
        if(room2Availability)
        {
            return(
                <Row className="row-m-20">
                    <Col className="col-m-b-20 remove-padding">
                        <div className="textContainer">
                        Room 2 single double £58 dogs welcome <br/>
                        A travel cot fits in all rooms and is free of charge.<br/>
                        <Button onClick={() => openModel(2)} style={{marginRight:10}}>Room Info</Button><Button onClick={(event) => {validateAndBook(event, "Room 2")}}>Book Now</Button>
                        </div>
                    </Col>
                </Row>
            )
        }
    }
    const Room3Results = () =>{
        if(room3Availability)
        {
            return(
                <Row className="row-m-20">
                    <Col className="col-m-b-20 remove-padding">
                        <div className="textContainer">
                        Room 3 double twin. Up to 2 additional guests<br/>
                        £80p/n Additional Adult Guests:£15 Additional Child Guests: £10<br/>
                        A travel cot fits in all rooms and is free of charge.<br/>
                        <Button onClick={() => openModel(3)} style={{marginRight:10}}>Room Info</Button><Button onClick={(event) => {validateAndBook(event, "Room 3")}}>Book Now</Button>
                        </div>
                    </Col>
                </Row>
            )
        }
    }
    const Room4Results = () =>{
        if(room4Availability)
        {
            return(
                <Row className="row-m-20">
                    <Col className="col-m-b-20 remove-padding">
                        <div className="textContainer">
                            Room 4 double twin. Up to 2 additional guests<br/>
                            £80p/n Additional Adult Guests:£15 Additional Child Guests: £10<br/>
                            A travel cot fits in all rooms and is free of charge.<br/>
                            <Button onClick={() => openModel(4)} style={{marginRight:10}}>Room Info</Button><Button onClick={(event) => {validateAndBook(event, "Room 4")}}>Book Now</Button>
                        </div>
                    </Col>
                </Row>
            )
        }
    }
    const Room5Results = () =>{
        if(room5Availability)
        {
            return(
                <Row className="row-m-20">
                    <Col className="col-m-b-20 remove-padding">
                        <div className="textContainer">
                            Room 5 is available, double. No additional guests. Dogs welcome<br/>
                            A travel cot fits in all rooms and is free of charge.<br/>
                            <Button onClick={(event) => {validateAndBook(event, "Room 5")}}>Book Now</Button>
                        </div>
                    </Col>
                </Row>
            )
        }
    }
    const Room6Results = () =>{
        if(room6Availability)
        {
            return(
                <Row className="row-m-20">
                    <Col className="col-m-b-20 remove-padding">
                        <div className="textContainer">
                            Room 6 is available. Additional space for 1 child under 8 available. No Dogs<br/>
                            Additional Child Guest: £10<br/>
                            A travel cot fits in all rooms and is free of charge.<br/>
                            <Button onClick={(event) => {validateAndBook(event, "Room 6")}}>Book Now</Button>
                        </div>
                    </Col>
                </Row>
            )
        }
    }
    const Room7Results = () =>{
        if(room7Availability)
        {
            return(
                <Row className="row-m-20">
                    <Col className="col-m-b-20 remove-padding">
                        <div className="textContainer">
                            Room 7 is available. Additioanl space for 1 child under 8 available. No Dogs<br/>
                            Additional Child Guest: £10<br/>
                            A travel cot fits in all rooms and is free of charge.<br/>
                            <Button onClick={(event) => {validateAndBook(event, "Room 7")}}>Book Now</Button>
                        </div>
                    </Col>
                </Row>
            )
        }
    }
    const Room8Results = () =>{
        if(room8Availability)
        {
            return(
                <Row className="row-m-20">
                    <Col className="col-m-b-20 remove-padding">
                        <div className="textContainer">
                            Room 8 double twin. Up to 2 additional guests<br/><br/>
                            £80p/n Additional Adult Guests:£15 Additional Child Guests: £10<br/>
                            A travel cot fits in all rooms and is free of charge.<br/>
                            <Button onClick={(event) => {validateAndBook(event, "Room 8")}}>Book Now</Button>
                        </div>
                    </Col>
                </Row>
            )
        }
    }

    const validateAndBook = (event, room) => {
        navigate("/bookingForm", {
            state: {
                room,
                bookFrom,
                bringingDogs,
                numberOfNights,
                singleOccupancy
            }
        })
    }

    const results = () => {
        if(haveAllData)
        {
        return(
            <div>
                {Room1Results()}
                {Room2Results()}
                {Room3Results()}
                {Room4Results()}
                {Room5Results()}
                {Room6Results()}
                {Room7Results()}
                {Room8Results()}

            </div>
        )
        }
    }


    function lastSunday(month, year) {
        var d = new Date();
        var lastDayOfMonth = new Date(Date.UTC(year || d.getFullYear(), month+1, 0));
        var day = lastDayOfMonth.getDay();
        return new Date(Date.UTC(lastDayOfMonth.getFullYear(), lastDayOfMonth.getMonth(), lastDayOfMonth.getDate() - day));
      }
      
      function isBST(date) {
        var d = date || new Date();
        var starts = lastSunday(2, d.getFullYear());
        starts.setHours(1);
        var ends = lastSunday(9, d.getFullYear());
        starts.setHours(1);
        return d.getTime() >= starts.getTime() && d.getTime() < ends.getTime();
      }

    const checkData = (data, room) => {
        console.log("Here is the Room Being Checked:")
        console.log(room)
        console.log("Here is the data passed to CheckData function:")
        console.log(data)
        var newDateArray = [];
        var i;
        for (i = 0; i < data.length; i++) {
            var sbookFromDay = new Date(data[i]["bookedFrom"]["seconds"] * 1000).getDate().toString();
            var sbookFromMonth = ((new Date(data[i]["bookedFrom"]["seconds"] * 1000).getMonth())).toString()
            var sbookFromYear = (new Date(data[i]["bookedFrom"]["seconds"] * 1000).getFullYear()).toString();
            var startDate = new Date(sbookFromYear, sbookFromMonth, sbookFromDay)
             if(data[i].id === "0sP5Uzr9IcyTp29xNgKI"){
                     console.log(startDate)
             }
            var numberOfNightsBooked = data[i]["numberOfNights"]
            var z;
            
            newDateArray.push(startDate.getTime())


            for (z = 1; z < numberOfNightsBooked; z++) {
                newDateArray.push(new Date(startDate.addDays(z)).getTime())
            }
        }
        var wantToBookArray = []
        var bookFromDay = new Date(bookFrom).getDate().toString();
        var bookFromMonth = ((new Date(bookFrom).getMonth())).toString()
        var bookFromYear = (new Date(bookFrom).getFullYear()).toString();
        var newDate = new Date(bookFromYear, bookFromMonth, bookFromDay).valueOf()
        var z;
        
        var calculatedNumberOfNights = parseInt(numberOfNights) + 1;
        for (z = 1; z < calculatedNumberOfNights; z++) {
            console.log("adding day:" + z)
            var timestamp = new Date(newDate).addDays(z).getTime()
            timestamp = timestamp - 86400000
            wantToBookArray.push(timestamp)
        }
        console.log(wantToBookArray)
        var found = true;
        for (var i = 0; i < wantToBookArray.length; i++) {
            if (newDateArray.indexOf(wantToBookArray[i]) > -1) {
                found = false;
                break;
            }
        }
        return found
    }

    const checkAvailability = async (event) => {
        event.preventDefault()
        setCheckingAvailability(true)
        setInitialRoomData([])
        setRoom1(false)
        setRoom2(false)
        setRoom3(false)
        setRoom4(false)
        setRoom5(false)
        setRoom6(false)
        setRoom7(false)
        setRoom8(false)

           
        if (bringingDogs) {
            const room1 = firestore.collection('room1').orderBy('bookedFrom').startAt(new Date(bookFrom).addDays(-7)).endAt(new Date(bookFrom).addDays(7)).get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                var found = checkData(data, 'room1')
                setRoom1(found);
            })
            const room2 = firestore.collection('room2').orderBy('bookedFrom').startAt(new Date(bookFrom).addDays(-7)).endAt(new Date(bookFrom).addDays(7)).get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                var found = checkData(data, 'room2')
                setRoom2(found);
            })
            const room4 = firestore.collection('room4').orderBy('bookedFrom').startAt(new Date(bookFrom).addDays(-7)).endAt(new Date(bookFrom).addDays(7)).get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                var found = checkData(data, 'room4')
                setRoom4(found);
            })
            const room5 = firestore.collection('room5').orderBy('bookedFrom').startAt(new Date(bookFrom).addDays(-7)).endAt(new Date(bookFrom).addDays(7)).get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                var found = checkData(data, 'room5')
                setRoom5(found);
                
            })
            
            Promise.all([
                room1,
                room2,
                room4,
                room5
            ]).then(() => {
                setCheckingAvailability(false)
                setHaveAllData(true)
            })
        }
        else {
            const room1 = firestore.collection('room1').orderBy('bookedFrom').startAt(new Date(bookFrom).addDays(-7)).endAt(new Date(bookFrom).addDays(7)).get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                var found = checkData(data,'room1')
                setRoom1(found);
            })
            const room2 = firestore.collection('room2').orderBy('bookedFrom').startAt(new Date(bookFrom).addDays(-7)).endAt(new Date(bookFrom).addDays(7)).get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                var found = checkData(data, 'room2')
                setRoom2(found);
            })
            const room3 = firestore.collection('room3').orderBy('bookedFrom').startAt(new Date(bookFrom).addDays(-7)).endAt(new Date(bookFrom).addDays(7)).get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                var found = checkData(data, 'room3')
                setRoom3(found);
            })
            const room4 = firestore.collection('room4').orderBy('bookedFrom').startAt(new Date(bookFrom).addDays(-7)).endAt(new Date(bookFrom).addDays(7)).get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                var found = checkData(data, 'room4')
                setRoom4(found);
            })
            const room5 = firestore.collection('room5').orderBy('bookedFrom').startAt(new Date(bookFrom).addDays(-7)).endAt(new Date(bookFrom).addDays(7)).get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                var found = checkData(data, 'room5')
                setRoom5(found);
                
            })
            const room6 = firestore.collection('room6').orderBy('bookedFrom').startAt(new Date(bookFrom).addDays(-7)).endAt(new Date(bookFrom).addDays(7)).get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                var found = checkData(data, 'room6')
                setRoom6(found);
            })
            const room7 = firestore.collection('room7').orderBy('bookedFrom').startAt(new Date(bookFrom).addDays(-7)).endAt(new Date(bookFrom).addDays(7)).get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                var found = checkData(data, 'room7')
                setRoom7(found);
            })
            const room8 = firestore.collection('room8').orderBy('bookedFrom').startAt(new Date(bookFrom).addDays(-7)).endAt(new Date(bookFrom).addDays(7)).get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                var found = checkData(data, 'room8')
                setRoom8(found);
            })
            
            Promise.all([
                room1,
                room2,
                room3,
                room4,
                room5,
                room6,
                room7,
                room8
            ]).then(() => {
                setCheckingAvailability(false)
                setHaveAllData(true)
            })
        }
    }

    const displayBookingForm = () => {
        if (lockdownArray) {

            if (checkingAvailability) {
                return (
                    <Spinner />
                )
            }
            else {
                return (
                    <Row className="row-m-20">
                        <Col className="col-m-b-20 remove-padding">
                            <div className="textContainer">
                                <h3>Check Availability:</h3>
                                <Form>
                                    <Row form className="row-m-20">
                                        <Col>
                                            <Label>Check In Date</Label>
                                            {CalendarDisplay()}
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="nights">Number of Nights</Label>
                                                <Input onChange={(event) => onChangeHandler(event)} type="select" name="numberOfNights" id="numberOfNights" value={numberOfNights}>
                                                    <option value="0"></option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                    <option value="6">6</option>
                                                    <option value="7">7</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="dogs">Dogs</Label>
                                                <Input onChange={(event) => onChangeHandler(event)} value={dogs} type="select" name="dogs" id="dogs">
                                                    <option value="0"></option>
                                                    <option value="1">Yes</option>
                                                    <option value="2">No</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        
                                    </Row>
                                    <Row form className="row-m-20">
                                    <Col md={3}>
                                        <FormGroup check>
        <Label check>
          <Input type="checkbox" name="singleOccupancy" onChange={(event) => onChangeHandler(event)} />{' '}
          Single Occupancy
        </Label>
      </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form className="row-m-20">
                                        <Col>
                                            <FormGroup>
                                                <Button onClick={(e) => { checkAvailability(e) }}>Check Now</Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>
                                <Row>
                                    <Col>Please ensure that all fields are filled</Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                )
            }

        }
    }


    const displayLockdownGuidelines = () => {
        if (!lockdownArray) {
            return (
                <Row className="row-m-20">
                    <Col className="col-m-b-20 remove-padding">
                        <div className="textContainer">
                            Current government guidelines advise overnight stays are applicable in a number of circumstances: <br />
                        You are allowed to stay overnight away from your home if you:
                        <ul>
                                <li>are unable to return to your main residence</li>
                                <li>need accommodation while moving house</li>
                                <li>need accommodation to attend a funeral or related commemorative event</li>
                                <li>require accommodation for work purposes or to provide voluntary services</li>
                                <li>are a child requiring accommodation for school or care</li>
                                <li>are homeless, seeking asylum or a vulnerable person seeking refuge</li>
                                <li>are an elite athlete or their support staff or parent, if the athlete is under 18 and it is necessary to be outside of the home for training or competition</li>
                            </ul>
                            <div style={{ textAlign: "center" }}>
                                Please <a href="/contact" style={{ color: "black" }}>CLICK HERE</a> to contact us to book
                        </div>

                        </div>
                    </Col>
                </Row>
            )
        }
    }

    return (

        <Container fluid>
            <TileContainer title={"Accommodation"} />
            <Row className="row-m-20">
                <Col md={6} className="col-m-b-20 remove-padding">
                    <div className="textContainer">
                        <p>Our Accommodation features eight individual en-suite bedrooms, decorated to the highest standards and fully equipped with all of the facilities that you would expect for a cosy, comfy stay. Due to the historic nature of our building all rooms are accessed via stairs.</p>
                        <p>Prices per night are-</p>
                        <ul>
                            <li>Standard Double room £65</li>
                            <li>Standard Twin room £70</li>
                            <li>Superior Room £80</li>
                            <li>Superior Room as Twin £85</li>
                            <li>Additional beds to suit family occupancy £10 per additional guest</li>
                            <li>Single occupancy of a standard room £58</li>
                        </ul>

                    </div>
                </Col>
            </Row>
            <Row className="row-m-20">
                <Col className="remove-padding">
                    <div>
                        <img src={invisiBreak} className="center" style={{ width: "100%", height: "50%", borderRadius: 5, opacity: 0.5 }} />
                    </div>
                </Col>
            </Row>
            {displayBookingForm()}
            {results()}
            <div>
      
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Room {roomData[0].RoomNumber}</ModalHeader>
        <ModalBody>
        <AccomoRoomCarousel roomData = {roomData[0]}/>
          {roomData[0].RoomInfo}
        </ModalBody>
        <ModalFooter>
          <Button onClick={(event) => {validateAndBook(event, "Room " + roomData[0].RoomNumber)}}>Book Now</Button>{' '}
          <Button color="secondary" onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>

            {displayLockdownGuidelines()}
            <Row className="row-m-20">
                <Col className="remove-padding" md={{ size: 8, offset: 2 }}>
                    <div className="textContainer">
                        <AccommoCarousel />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Accommodation

Array.prototype.remove = function () {
    var what, a = arguments, L = a.length, ax;
    while (L && this.length) {
        what = a[--L];
        while ((ax = this.indexOf(what)) !== -1) {
            this.splice(ax, 1);
        }
    }
    return this;
};

Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}