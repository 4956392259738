import React from "react"
import {Container, Row, Col} from "reactstrap"
const TileContainer = ({title}) => {
return(
            <Row className="row-m-20">
                <Col className="title" style={{ textAlign: "center" }}>
                    <h1>{title}</h1>
                </Col>
            </Row>
)
}

export default TileContainer