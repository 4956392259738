import React, { useState } from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';
import slide1 from "../assets/IMG_0384.JPG"
import slide2 from "../assets/IMG_0386.JPG"
import slide3 from "../assets/IMG_0388.JPG"
import slide4 from "../assets/IMG_0395.JPG"
import slide5 from "../assets/IMG_0399.JPG"
import slide6 from "../assets/IMG_0400.JPG"
import slide7 from "../assets/IMG_0402.JPG"
import slide8 from "../assets/IMG_0405.JPG"
const items = [
  {
    src: slide1,
    altText: 'Slide 1',
    caption: 'Slide 1'
  },
  {
    src: slide2,
    altText: 'Slide 1',
    caption: 'Slide 1'
  },
  {
    src: slide3,
    altText: 'Slide 1',
    caption: 'Slide 1'
  },
  {
    src: slide4,
    altText: 'Slide 1',
    caption: 'Slide 1'
  },
  {
    src: slide5,
    altText: 'Slide 1',
    caption: 'Slide 1'
  },
  {
    src: slide6,
    altText: 'Slide 1',
    caption: 'Slide 1'
  },
  {
    src: slide7,
    altText: 'Slide 1',
    caption: 'Slide 1'
  },
  {
    src: slide8,
    altText: 'Slide 1',
    caption: 'Slide 1'
  }
];

const AccomoCarousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
          <div style={{textAlign:"center", backgroundColor:"#83868b"}}>
        <img src={item.src} alt={item.altText} style={{maxWidth:"100%", alignSelf:"center"}}/>
        </div>
        {/* <CarouselCaption captionText={item.caption} captionHeader={item.caption} /> */}
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
    >
      <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />
      {slides}
      <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
      <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
    </Carousel>
  );
}

export default AccomoCarousel;