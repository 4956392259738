import React, { useEffect, useState } from "react"
import { Container, Row, Col, Spinner, Button,  Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input  } from "reactstrap";
import "../app.css"
import TitleContainer from "../../containers/TitleContainer"
import { firestore } from "../../firebase"
import ReactTable from 'react-table-v6'
import 'react-table-v6/react-table.css'
import moment from "moment"
import { Calendar, momentLocalizer  } from 'react-big-calendar' 
import 'react-big-calendar/lib/css/react-big-calendar.css';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import "./adminBooking.css"



const AdminBooking = (props) => {
    const [newBookings, setNewBookings] = useState([])
    const [haveAllData, setHaveAllData] = useState(false)
    const [bookings, setBookings] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const localizer = momentLocalizer(moment)
    const [modal, setModal] = useState(false);
    const [eventData, setEventDataForModal] = useState([])
    const [createNewBookingClicked, setCreateNewBooking] = useState(false)
    const [createNewBlockBookingClicked, setCreateNewBlockBooking] = useState(false)
    const [successfullyBooked, setSuccessfullyBooked] = useState(false)
    const [bookingTitle, setTitle] = useState();
    const [bookingFirstName, setFirstName] = useState();
    const [bookingLastName, setLastName] = useState();
    const [bookingTel, setTel] = useState();
    const [bookingEmail, setEmail] = useState();
    const [bookingAddress, setAddress] = useState();
    const [bookingPostcode, setPostcode] = useState();
    const [bookingAdditionalAdults, setAdditionalAdults] = useState(0);
    const [bookingAdditionalChildren, setAdditionalChild] = useState(0);
    const [bookingTravelCotRequired, setTravelCotRequired] = useState(false)
    const [bookingSingleOccupancy, setSingleOccupancy] = useState(false)
    const [bookingNumberOfNights, setNumberOfNights] = useState(1)
    const [bookingDogs, setDogs] = useState(false)
    const [bookingRoomName, setRoom] = useState("room1")
    const [bookingAdditionalInfo, setAdditionalInfo] = useState("")
    const [editNotes, setEditNotesBlock] = useState(false)
    const [notesValue, setNotesValue] = useState("");
    const toggle = () => setModal(!modal);
    const [readOnly, setReadOnly] = useState(false)
    const [openModelNotes, setOpenModelNotes] = useState(false)
    const [modelNotesValue, setModelNotesValue] = useState("")
    const [displayCancelledBookingTable, setDisplayCancelledBookingTable] = useState(false);
    const [gotCancelledBookings, setGotCancelledBookings] = useState(false);
    const [cancelledBookings, setCancelledBookings] = useState([])
    
    function refreshPage() {
        window.location.reload(false);
      }
    const onChangeHandler = (event) => {
        const { name, value } = event.currentTarget;

        if (name === 'title') {
            setTitle(value);
        }
        else if (name === "firstName") {
            setFirstName(value);
        }
        else if (name === "lastName") {
            setLastName(value);
        }
        else if (name === "tel") {
            setTel(value);
        }
        else if (name === "email") {
            setEmail(value);
        }
        else if (name === "address") {
            setAddress(value)
        }
        else if (name === "postcode") {
            setPostcode(value)
        }
        else if (name === "additionalAdults")
        {
            setAdditionalAdults(value)
        }
        else if (name === "additionalChildren"){
            setAdditionalChild(value)
        }
        else if (name === "travelCotRequired"){
            setTravelCotRequired(value)
        }
        else if (name === "singleOccupancy"){
            setSingleOccupancy(check => !check)
        }
        else if (name === "numberOfNights"){
            setNumberOfNights(value)
        }
        else if (name === "bringingDogs"){
            setDogs(true)
        }
        else if (name === "room"){
            setRoom(value)
        }
        else if (name === "notes"){
            setNotesValue(value)
        }
        else if (name === "modelNotes"){
            setModelNotesValue(value)
        }
        else if (name === "AdditionalInfo"){
            setAdditionalInfo(value)
        }

    }
    const getDateWithoutTime = (dateWithTime) => {
        return moment(new Date(dateWithTime)).format("Do MMMM YYYY");
        //new Date(dateWithTime.getFullYear(), dateWithTime.getMonth(), dateWithTime.getDate())

    }
    const getDogsAsYesNo = (e) => {
        if(e)
        {
            return "Yes"
        }
        else{
            return "No"
        }
    }
    const createBooking = () => {
        if(bookingTel.length < 1)
        {
            alert("Please enter mobile number");
            return;
        }
        var roomCollection = bookingRoomName
        firestore.collection("tempBooking" + roomCollection).add({
            RoomName: roomCollection,
            bookedFrom: startDate,
            numberOfNights: bookingNumberOfNights,
            bringingDogs: bookingDogs,
            title: bookingTitle,
            firstName: bookingFirstName,
            lastName: bookingLastName,
            tel: bookingTel,
            email: bookingEmail,
            address: bookingAddress,
            postcode: bookingPostcode,
            additionalAdults: bookingAdditionalAdults,
            additionalChildren: bookingAdditionalChildren,
            travelCotRequired: bookingTravelCotRequired,
            singleOccupancy: bookingSingleOccupancy,
            createDateTime: new Date(),
            AdditionalInfo: bookingAdditionalInfo

        }).then(() => {
            setSuccessfullyBooked(true)
            refreshPage()
        });
    }

    const MyCalendar = (props) => {
        if(!haveAllData){
            return(
                <div>
                    <Spinner/>
                </div>
            )
        }
        else{
        return(
        <div>
          <Calendar
            localizer={localizer}
            events={bookings}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 800 }}
            popup={true}
            onSelectEvent={event => openModalWithEventDetails(event)}
          />
        </div>
        )
        }
    }
      
    const bookedDates = (start, end) => {
        if(end <= start){
            return(<div>
               <b>Booked:</b> {moment(start).format('DD-MM-YYYY')}
            </div>)
        }
        else{
            return(
                <div><b>Booked:</b>{moment(start).format('DD-MM-YYYY')} - {moment(end).format('DD-MM-YYYY')}</div>
            )
            
        }
    }

    const notesForModel = (eventData, modelNotesValueParam) => {
        if(!openModelNotes){
            if(modelNotesValueParam.length > 0){
                return(
                    <div>{modelNotesValueParam}</div>
                )
            }
        if(typeof eventData.notes !== "undefined"){
            return(<div>{eventData.notes}</div>)
        }
    }
    else{
        
        return(<div><Input name="modelNotes" type="textarea" rows={6} onChange={(e) => {onChangeHandler(e)}} value={modelNotesValue}></Input></div>)
    }
    }
    const updateNotes = (id) =>{
        firestore.collection("confirmedBookings").doc(id).update({
            notes:modelNotesValue
        }).then(() => {
            setOpenModelNotes(false)
        })
    }
    const cancelEditNotesModel = () => {
        setOpenModelNotes(false)
        setModelNotesValue("")
    }

    const opentheModelNotes = (notes, modelNotesValueParam) => {
        console.log("the notes are:" + notes)
        setOpenModelNotes(true)
        if(modelNotesValueParam.length > 0)
        {
            setModelNotesValue(modelNotesValueParam)
            return;
        }

        if(typeof notes !== "undefined"){
            setModelNotesValue(notes)
        }
    }
    const modelNotesButton = (id, notes, modelNotesValueParam) => {
        if(openModelNotes){
            return(
                <div><Button onClick={() => updateNotes(id)}>Save Notes</Button><Button onClick={() => cancelEditNotesModel()}>Cancel Edit</Button></div>
            )
        }
        else{
            return(
                <div><Button onClick={() => opentheModelNotes(notes, modelNotesValueParam)}>Edit Notes</Button></div>
            )
        }
    }
        const modalevent = ()=>{
            return (
                <Modal isOpen={modal} toggle={() => clearNotesAndCloseModel()}>
                <ModalHeader toggle={() => clearNotesAndCloseModel()}>{eventData.title}</ModalHeader>
                <ModalBody>
                  <b>Name:</b> {eventData.firstName} {eventData.lastName} <br/>
                   {bookedDates(eventData.start, eventData.end)}
                  <b>Tel:</b> {eventData.tel} <b>Email:</b> {eventData.email}<br/>
                  <b>Address:</b> {eventData.address} {eventData.postcode}<br/>
                  <b>Bringing Dogs:</b> {boolToText(eventData.bringingDogs)} <b>Travel Cot Required:</b> {boolToText(eventData.travelCotRequired)} <br/>
                  <b>Additional Adults:</b> {eventData.additionalAdults} <b>Additional Children:</b> {eventData.additionalChildren} <br/>
                  <b>Single Occupancy:</b> {boolToText(eventData.singleOccupancy)} <br/>
                  <b>Client Additional Information:</b> {eventData.additionalInfo} <br/>
                  <b>Notes:</b> {notesForModel(eventData, modelNotesValue)}
                   {modelNotesButton(eventData.id, eventData.notes, modelNotesValue)}
                </ModalBody>
                <ModalFooter>
                  <Button disabled={readOnly} color="primary" onClick={() => cancelConfirmedBooking(eventData.id)}>Cancel Booking</Button>{' '}
                  <Button color="secondary" onClick={() => clearNotesAndCloseModel()}>Close</Button>
                </ModalFooter>
              </Modal>
            )
        }
        const openModalWithEventDetails = (event) => {
          setEventDataForModal(event)
          toggle()
        } 

        const clearNotesAndCloseModel = () => {
            console.log("clearing the notes and closing the model!")
            if(openModelNotes){
                setOpenModelNotes(false)
            }
            setModelNotesValue("")
            toggle()
        }

        const cancelConfirmedBooking = (id) => {
            if(window.confirm("Are you sure you want to cancel this booking?")){
                firestore.collection("confirmedBookings").doc(id).delete().then(() => {
                    toggle()
                    getData()
                })
            }
            else{
                toggle()
            }
            
        }
    
    useEffect(() => {
        const fetchData = async () => {
            
            if(props.user.userType != "readWrite"){
            
                setReadOnly(true)
            }
            const room1 = firestore.collection('tempBookingroom1').orderBy('bookedFrom').get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                newBookings.push(data);
            })
            const room2 = firestore.collection('tempBookingroom2').orderBy('bookedFrom').get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                newBookings.push(data);
            })
            const room3 = firestore.collection('tempBookingroom3').orderBy('bookedFrom').get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                newBookings.push(data);
            })
            const room4 = firestore.collection('tempBookingroom4').orderBy('bookedFrom').get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                newBookings.push(data);
            })
            const room5 = firestore.collection('tempBookingroom5').orderBy('bookedFrom').get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                newBookings.push(data);
            })
            const room6 = firestore.collection('tempBookingroom6').orderBy('bookedFrom').get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                newBookings.push(data);
            })
            const room7 = firestore.collection('tempBookingroom7').orderBy('bookedFrom').get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                newBookings.push(data);
            })
            const room8 = firestore.collection('tempBookingroom8').orderBy('bookedFrom').get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                newBookings.push(data);
            })
            const getBookings = firestore.collection('confirmedBookings').orderBy('bookedFrom').get().then((snapshot) => {
                const data = snapshot.docs.map((doc) =>({
                    id: doc.id,
                    start: new Date(doc.data().bookedFrom.seconds * 1000),
                    end: new Date((doc.data().bookedFrom.seconds + (86400 * (doc.data().numberOfNights-1))) * 1000),
                    ...doc.data()
                }));
                setBookings(data)
            })
            Promise.all([
                room1,
                room2,
                room3,
                room4,
                room5,
                room6,
                room7,
                room8,
                getBookings
            ]).then(() => {
                setHaveAllData(true)
            })
        }

        fetchData();

    }, [])

    const getData = async () => {
        const clearNewBookings = setNewBookings([])
        const clearBookings = setBookings([])
        Promise.all([
            clearNewBookings,
            clearBookings
        ]).then(() => {

            const room1 = firestore.collection('tempBookingroom1').orderBy('bookedFrom').get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                newBookings.push(data);
            })
            const room2 = firestore.collection('tempBookingroom2').orderBy('bookedFrom').get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                newBookings.push(data);
            })
            const room3 = firestore.collection('tempBookingroom3').orderBy('bookedFrom').get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                newBookings.push(data);
            })
            const room4 = firestore.collection('tempBookingroom4').orderBy('bookedFrom').get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                newBookings.push(data);
            })
            const room5 = firestore.collection('tempBookingroom5').orderBy('bookedFrom').get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                newBookings.push(data);
            })
            const room6 = firestore.collection('tempBookingroom6').orderBy('bookedFrom').get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                newBookings.push(data);
            })
            const room7 = firestore.collection('tempBookingroom7').orderBy('bookedFrom').get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                newBookings.push(data);
            })
            const room8 = firestore.collection('tempBookingroom8').orderBy('bookedFrom').get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                newBookings.push(data);
            })
            const getBookings = firestore.collection('confirmedBookings').orderBy('bookedFrom').get().then((snapshot) => {
                const data = snapshot.docs.map((doc) =>({
                    id: doc.id,
                    start: new Date(doc.data().bookedFrom.seconds * 1000),
                    end: new Date((doc.data().bookedFrom.seconds + (86400 * doc.data().numberOfNights)) * 1000),
                    ...doc.data()
                }));
                setBookings(data)
            })
            Promise.all([
                room1,
                room2,
                room3,
                room4,
                room5,
                room6,
                room7,
                room8,
                getBookings
            ]).then(() => {
                setHaveAllData(true)
            })
        })

        
    }
    const timeStampToDate = (d) => {
        return moment(d.seconds * 1000).format('ddd DD MM YYYY')
    }

    const combineName = (t, f, l) => {
        return t.concat(" ", f, " ", l)
    }

    const roomNameToRoomName = (d) => {
        console.log("d is:" + d)
        if(d === "room1")
        {
            return("Room 1")
        }
        else if(d === "room2")
        {
            return("Room 2")
        }
        else if(d === "room3")
        {
            return("Room 3")
        }
        else if(d === "room4")
        {
            return("Room 4")
        }
        else if(d === "room5")
        {
            return("Room 5")
        }
        else if(d === "room6")
        {
            return("Room 6")
        }
        else if(d === "room7")
        {
            return("Room 7")
        }
        else if(d === "room8")
        {
            return("Room 8")
        }
        else{
            return d
        }
    }

    const submitBlockBooking = () => {
        var roomCollection = bookingRoomName
        firestore.collection("tempBooking" + roomCollection).add({
            RoomName: roomCollection,
            bookedFrom: startDate,
            numberOfNights: bookingNumberOfNights,
            bringingDogs: bookingDogs,
            title: "Mr",
            firstName: "Block",
            lastName: "Booking",
            tel: "0777777777",
            email: "zzz@whiteharttearoom.co.uk",
            address: "Block Booking",
            postcode: "Block Booking",
            additionalAdults: 0,
            additionalChildren: 0,
            travelCotRequired: false,
            singleOccupancy: true,
            createDateTime: new Date(),
            AdditionalInfo: bookingAdditionalInfo

        }).then(() => {
            setSuccessfullyBooked(true)
            refreshPage()
        });
    }

    const displayBookingForm = () => {
        if(createNewBlockBookingClicked){
            return(
            <Row className="row-m-20">
                <Col className="col-md-b-20 remove-padding">
                    <Form>
                        <Row form className="row-m-20">
                            <Col md={3}>
                                <Label for="checkInDate">Start Date</Label><br/>
                                <DatePicker dateFormat="dd/MM/yyyy" selected={startDate} onChange={date => setStartDate(date)} />
                            </Col>
                            <Col md={3}>
                                    <Label for="numberOfNights">Number Of Nights</Label>
                                    <Input onChange={event => onChangeHandler(event)} type="select" id="numberOfNights" name="numberOfNights" value={bookingNumberOfNights}>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                    </Input>

                                </Col>
                                <Col md={3}>
                                    <Label for="room">Room</Label>
                                    <Input type="select" id="room" name="room" onChange={event => onChangeHandler(event)}>
                                        <option value="room1">Room 1</option>
                                        <option value="room2">Room 2</option>
                                        <option value="room3">Room 3</option>
                                        <option value="room4">Room 4</option>
                                        <option value="room5">Room 5</option>
                                        <option value="room6">Room 6</option>
                                        <option value="room7">Room 7</option>
                                        <option value="room8">Room 8</option>
                                    </Input>
                                </Col>
                        </Row>
                        <Row form className="row-m-20">
                            <Col md={12}>
                                        <FormGroup>
                                            <Label for="bookingAdditionalInfo">Additional Info</Label>
                                            <Input type="textarea" rows={5} name="bookingAdditionalInfo" onChange={(event) => onChangeHandler(event)}/>
                                        </FormGroup>
                                        </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button onClick={() =>submitBlockBooking()}>Submit Block Booking</Button>
                                <Button style={{marginLeft:10}} disbaled={readOnly} onClick={(e) => setCreateNewBlockBooking(false)}>Cancel Block Booking</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            )
        }
       
        if(createNewBookingClicked){
        if(successfullyBooked)
        {
        
            return(
                <Row className="row-m-20" style={{marginTop:10}}>
                    <Col className="col-m-b-20 remove-padding">
                    <div className="textContainer">
                    Thank you for your booking enquiry. We will be in touch shortly to confirm your booking and to complete payment.
                        </div>
                    </Col>
                </Row>
            )
            
        }else{
            return(
                <div style={{marginTop:10}}>
            <Row className="row-m-20">
                <Col className="col-m-b-20 remove-padding">
                    <div className="textContainer">
                        <Form>
                            <Row form className="row-m-20">
                                <Col md={3}>
                                    <Label for="checkInDate">Check In Date</Label><br/>
                                    <DatePicker dateFormat="dd/MM/yyyy" selected={startDate} onChange={date => setStartDate(date)} />
                                </Col>
                                <Col md={3}>
                                    <Label for="numberOfNights">Number Of Nights</Label>
                                    <Input onChange={event => onChangeHandler(event)} type="select" id="numberOfNights" name="numberOfNights" value={bookingNumberOfNights}>
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>

                                    </Input>
                                </Col>
                                <Col md={3}>
                                    <Label for="bringingDogs">Dogs</Label>
                                    <Input type="select" id="bringingDogs" name="bringingDogs" onChange={event => onChangeHandler(event)} value={bookingDogs}>
                                        <option value="true">Yes</option>
                                        <option value="false">No</option>
                                    </Input>
                                </Col>
                                <Col md={3}>
                                    <Label for="room">Room</Label>
                                    <Input type="select" id="room" name="room" onChange={event => onChangeHandler(event)}>
                                        <option value="room1">Room 1</option>
                                        <option value="room2">Room 2</option>
                                        <option value="room3">Room 3</option>
                                        <option value="room4">Room 4</option>
                                        <option value="room5">Room 5</option>
                                        <option value="room6">Room 6</option>
                                        <option value="room7">Room 7</option>
                                        <option value="room8">Room 8</option>
                                    </Input>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="additionalAdults">Additional Adults</Label>
                                        <Input onChange={(event) => onChangeHandler(event)} type="select" name="additionalAdults" id="additionalAdults">
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="additionalChildren">Additional Children</Label>
                                        <Input onChange={(event) => onChangeHandler(event)} type="select" name="additionalChildren" id="additionalChildren">
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="travelCotRequired">Travelcot Required</Label>
                                        <Input onChange={(event) => onChangeHandler(event)} type="select" name="travelCotRequired" id="travelCotRequired">
                                            <option value="false">No</option>
                                            <option value="true">Yes</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="title">Title</Label>
                                        <Input onChange={(event) => onChangeHandler(event)} type="select" name="title" id="title">
                                            <option value=""></option>
                                            <option value="Mr">Mr</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Miss">Miss</option>
                                            <option value="Ms">Ms</option>
                                            <option value="Mx">Mx</option>
                                            <opion value="Other">Other</opion>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="firstName">First Name</Label>
                                        <Input onChange={(event) => onChangeHandler(event)} type="text" name="firstName" id="firstName" />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="lastName">Last Name</Label>
                                        <Input onChange={(event) => onChangeHandler(event)} type="text" name="lastName" id="lastName" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="tel">Telephone Number</Label>
                                        <Input onChange={(event) => onChangeHandler(event)} type="text" name="tel" id="tel" />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="email">Email</Label>
                                        <Input onChange={(event) => onChangeHandler(event)} type="text" name="email" id="email" />
                                    </FormGroup>
                                </Col>
                            </Row>
                           
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="address">Address</Label>
                                        <Input onChange={(event) => onChangeHandler(event)} type="textarea" rows={6} name="address" id="address" />
                                    </FormGroup>
                                </Col>
                                                            </Row>
                            <Row form>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="postcode">Postcode</Label>
                                        <Input onChange={(event) => onChangeHandler(event)} type="text" name="postcode" id="postcode" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                            <Col md={3}>
                                        <FormGroup check>
        <Label check>
          <Input type="checkbox" name="singleOccupancy" onChange={(event) => onChangeHandler(event)} />{' '}
          Single Occupancy
        </Label>
      </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form className="row-m-20">
                                        <FormGroup>
                                            <Label for="bookingAdditionalInfo">Additional Info</Label>
                                            <Input type="textarea" rows={5} name="bookingAdditionalInfo" onChange={(event) => onChangeHandler(event)}/>
                                        </FormGroup>
                                    </Row>
                            <Row form className="row-m-20">
                                <Col>
                                    <FormGroup>
                                        <Button disabled={readOnly} onClick={(e) => createBooking(e)}>Book Now</Button>
                                        <Button style={{marginLeft:10}} disabled={readOnly} onClick={(e) => setCreateNewBooking(false)}>Cancel New Booking</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
            </Row>
                </div>
            )
        }
    }
    else{
        return(
            <div style={{marginTop:10}}>
                <Button disabled={readOnly} onClick={(e) => setCreateNewBooking(true)}>Create New Booking</Button>
                <Button style={{marginLeft:10}} disbaled={readOnly} onClick={(e) => setCreateNewBlockBooking(true)}>Create Block Booking</Button>
            </div>
        )
    }
    }

    const newBookingColumns = React.useMemo(
        () => [
            {
                Header: 'New Bookings',
                columns: [
                    {
                        Header: 'Room',
                        id: 'rom',
                        accessor: d => roomNameToRoomName(d.RoomName),
                    },
                    {
                        Header: 'Check In',
                        id: 'checkIn',
                        accessor: d => timeStampToDate(d.bookedFrom),
                    }
                ],
            }
        ],
        []
    )

    const cancelledBookingColumns = React.useMemo(
        () => [
            {
                Header: 'Cancelled Bookings',
                columns: [
                    {
                        Header: 'Room',
                        id: 'rom',
                        accessor: d => roomNameToRoomName(d.roomName),
                    },
                    {
                        Header: 'Check In',
                        id: 'checkIn',
                        accessor: d => timeStampToDate(d.bookedFrom),
                    }
                ],
            }
        ],
        []
    )

    const boolToText = (d) => {
        if(!d){
        return ("Yes")
        }
        return ("No")
    }

    const showNotes = (d) => {
        if(editNotes){
           return (
                <Input type="textarea" id="notes" name="notes" rows={3} onChange={(event) => onChangeHandler(event)} value={notesValue}/>
           )
        }
        else{
            return(<div>{notesValue}</div>)
        }
        
    }

    const showAdditionalInfo = (d) => {
        if(typeof d.additionalInfo !== "undefined"){
            return(d.additionalInfo)
        }
    }
    
    const setEditNotes = () => {
      
        setEditNotesBlock(true)
    }

    const cancelBooking = (id, roomName) => {

        firestore.collection("tempBooking"+roomName).doc(id).delete().then(() => {
            console.log("deleteing temp booking. Id:" + id + " RoomNumber:" + roomName)
            refreshPage()
        })
    }

    const deleteBookingForever = (id) => {

        if(window.confirm("Are you sure you want to delete this booking?")){
            firestore.collection("cancelledBookings").doc(id).delete().then(() => {
                refreshPage()
            })
        }
        else{
            return
        }
        
    }

    const restoreBooking = (originalData) => {
        console.log("restoring:")
        console.log(originalData)
        var addInfo = ""
        var notes = ""
        if(typeof originalData.additionalInfo !== "undefined"){
            addInfo = originalData.additionalInfo
        }

        if(typeof originalData.notes !== "undefined"){
            notes = originalData.notes
        }

        firestore.collection("tempBooking"+originalData.roomName).add({
            RoomName: originalData.roomName,
            title: originalData.bookingName.split(" ")[0], 
            firstName: originalData.bookingName.split(" ")[1], 
            lastName: originalData.bookingName.split(" ")[2],
            tel: originalData.tel,
            email: originalData.email,
            address: originalData.address,
            postcode: originalData.postcode,
            additionalAdults: originalData.additionalAdults,
            additionalChildren: originalData.additionalChildren,
            travelCotRequired: originalData.travelCotRequired,
            bringingDogs: originalData.bringingDogs,
            numberOfNights: originalData.numberOfNights,
            bookedFrom: originalData.bookedFrom,
            singleOccupancy:originalData.singleOccupancy || false,
            notes: notes,
            additionalInfo: addInfo
        })
        firestore.collection("cancelledBookings").doc(originalData.id).delete().then(() => {
            console.log("deleteing temp booking. Id:" + originalData.id + " RoomNumber:" + originalData.RoomName)
            refreshPage()
        })
    }

    const cancelAndKeepBookings = (originalData) => {
        var addInfo = ""
        var notes = ""
        if(typeof originalData.additionalInfo !== "undefined"){
            addInfo = originalData.additionalInfo
        }

        if(typeof originalData.notes !== "undefined"){
            notes = originalData.notes
        }

        firestore.collection("cancelledBookings").add({
            roomName: originalData.RoomName,
            bookingName: originalData.title + " " + originalData.firstName + " " + originalData.lastName,
            tel: originalData.tel,
            email: originalData.email,
            address: originalData.address,
            postcode: originalData.postcode,
            additionalAdults: originalData.additionalAdults,
            additionalChildren: originalData.additionalChildren,
            travelCotRequired: originalData.travelCotRequired,
            bringingDogs: originalData.bringingDogs,
            numberOfNights: originalData.numberOfNights,
            bookedFrom: originalData.bookedFrom,
            singleOccupancy:originalData.singleOccupancy || false,
            notes: notes,
            additionalInfo: addInfo
        })
        firestore.collection("tempBooking"+originalData.RoomName).doc(originalData.id).delete().then(() => {
            console.log("deleteing temp booking. Id:" + originalData.id + " RoomNumber:" + originalData.RoomName)
            refreshPage()
        })
    }

    const confirmBooking = (event, data, id, roomNumber) => {
        var notes = ""
        if(typeof data.notes !== "undefined"){
            notes = data.notes
        }

        var additionalInfo = ""
        if(typeof data.additionalInfo !== "undefined"){
            additionalInfo = data.additionalInfo
        }

        console.log(data.notes)
        console.log(notesValue)
        firestore.collection("confirmedBookings").add({
            title:data.RoomName,
            additionalAdults:data.additionalAdults,
            additionalChildren:data.additionalChildren,
            address:data.address,
            postcode:data.postcode,
            tel:data.tel,
            email:data.email,
            nameTitle:data.title,
            firstName:data.firstName,
            lastName:data.lastName,
            numberOfNights: data.numberOfNights,
            bringingDogs:data.bringingDogs,
            singleOccupancy: data.singleOccupancy,
            notes: notes,
            bookedFrom: data.bookedFrom,
            additionalInfo: additionalInfo

        }).then(() => {
            firestore.collection(roomNumber).add({
                bookedFrom: data.bookedFrom,
                numberOfNights:data.numberOfNights
            })
            cancelBooking(id, roomNumber)
        })
    }
    const saveNotes = (data) => {
        firestore.collection("tempBooking"+data.RoomName).doc(data.id).update({
            notes : notesValue
        }).then(() => {
            setEditNotesBlock(false)
        })
    }

    const cancelNotes = (data) => {
        if(typeof data.notes !== "undefined"){
            if(data.notes.length > 0){
                setNotesValue(data.notes)
                setEditNotesBlock(false)
            }
        }
        else{
            setEditNotesBlock(false)
        }
        

    }

    const notesButtons = (data) => {
        if(editNotes){
            return(
                <div>
                    <Button style = {{marginBottom:10, marginRight:10}} onClick={() => {saveNotes(data)}}>Save</Button> <Button style={{marginBottom:10}} onClick = {() => {cancelNotes(data)}}>Cancel</Button>
                </div>
            )
        }
        else{
        return(
            <Button style={{marginBottom:10}} onClick={() => {setEditNotes()}}>Add/Edit Notes</Button>
        )
    }
    }

    const getCancelledBookings = async () => {
        
        firestore.collection('cancelledBookings').orderBy('bookedFrom').limit(100).get().then((snapshot) => {
            const data = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            setCancelledBookings(data)
        }).then(() => {
            setDisplayCancelledBookingTable(true)
            setGotCancelledBookings(true)
        })
    }

    const displayCancelledBookings = () => {
        if(!displayCancelledBookingTable && !gotCancelledBookings){
            return(
            <Button onClick={() => getCancelledBookings()}>Display Cancelled Bookings</Button>
            )
        }else{
            if(cancelledBookings !== [])
            return(
                <div style={{marginTop:10}}>
            <ReactTable
                    data={cancelledBookings}
                    columns={cancelledBookingColumns}
                    defaultPageSize={10}
                    collapseOnDataChange={false}
                    SubComponent={row => {
                        var newRow = row.row
                        var originalData = row.original
                        if(typeof originalData.notes !== "undefined"){
                            setNotesValue(originalData.notes)
                        }
                        return (
                            <div style={{marginLeft:10}}>
                            <Row>
                                <Col md={3}>
                                    <div>
                                        <b>Name:</b> {originalData.bookingName}
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div>
                                    <b>Tel:</b> {originalData.tel}
                                    </div>
                                </Col>
                                <Col>
                                    <div>
                                    <b>Email:</b> {originalData.email}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                    <b> Address:</b> {originalData.address}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                    <b> Check In Date:</b> {timeStampToDate(originalData.bookedFrom)}
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div>
                                    <b> Number Of Nights: </b>{originalData.numberOfNights}
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <div>
                                    <b> Bringing Dogs: </b>{boolToText(originalData.brigingDogs)}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                    <b> Additional Adults: </b>{originalData.additionalAdults}
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div>
                                    <b> Additional Children: </b>{originalData.additionalChildren}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                    <b> Travel Cot Required: </b>{boolToText(originalData.travelCotRequired)}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <b>Notes: </b> {showNotes(originalData)} <br/>
                                        {notesButtons(originalData)}
                                    </div>
                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <b>Client Additional Info:</b> {showAdditionalInfo(originalData)}
                                    </div>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: 5}}>
                                <Col md={12}>
                                    <Button onClick={(event) => restoreBooking(originalData)}>Restore Booking</Button>
                                    <Button style={{marginLeft:10}} color="danger" onClick={(event) => deleteBookingForever(originalData.id)}>Delete Permanently</Button>
                                </Col>
                            </Row>
                             </div>

                        );
                    }}
                /></div>)
        }
    }

    const displayNewBookings = () => {


        if (!haveAllData) {
            return (
                <div style={{ textAlign: "center" }}>
                    loading bookings...<br /><br />
                    <Spinner />
                </div>
            )
        }
        else {
            var data = Object.values(newBookings).flatMap(arr => arr)
            return (
                <ReactTable
                    data={data}
                    columns={newBookingColumns}
                    defaultPageSize={10}
                    collapseOnDataChange={false}
                    SubComponent={row => {
                        var newRow = row.row
                        var originalData = row.original
                        if(typeof originalData.notes !== "undefined"){
                            setNotesValue(originalData.notes)
                        }
                        return (
                            <div>
                            <Row>
                                <Col md={3}>
                                    <div>
                                        <b>Name:</b> {combineName(originalData.title, originalData.firstName, originalData.lastName)}
                                    </div>
                                </Col>
                                <Col md={2}>
                                    <div>
                                    <b>Tel:</b> {originalData.tel}
                                    </div>
                                </Col>
                                <Col>
                                    <div>
                                    <b>Email:</b> {originalData.email}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                    <b> Address:</b> {originalData.address}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                    <b> Check In Date:</b> {timeStampToDate(originalData.bookedFrom)}
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div>
                                    <b> Number Of Nights: </b>{originalData.numberOfNights}
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <div>
                                    <b> Bringing Dogs: </b>{boolToText(originalData.brigingDogs)}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                    <b> Additional Adults: </b>{originalData.additionalAdults}
                                    </div>
                                </Col>
                                <Col md={12}>
                                    <div>
                                    <b> Additional Children: </b>{originalData.additionalChildren}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                    <b> Travel Cot Required: </b>{boolToText(originalData.travelCotRequired)}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <b>Notes: </b> {showNotes(originalData)} <br/>
                                        {notesButtons(originalData)}
                                    </div>
                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <div>
                                        <b>Client Additional Info:</b> {showAdditionalInfo(originalData)}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{paddingBottom:10}}>
                                    <Button disabled={readOnly} onClick={(event) => {cancelAndKeepBookings(originalData)}}>Cancel Booking</Button>
                                </Col>
                                </Row>
                                <Row>
                                <Col style={{paddingBottom:10}}>
                                    <Button disabled={readOnly} onClick={(event) => {confirmBooking(event, originalData, originalData.id, originalData.RoomName)}}>Confirm Booking</Button>
                                </Col>
                                </Row>
                            
                            </div>

                        );
                    }}
                />
            )
        }
    }



    return (
        <Container fluid>
            <TitleContainer title={"Bookings"} />
            <Row>
                <Col >
                    <h3>Upcoming Bookings</h3>
                </Col>
            </Row>
            <Row>
                <Col id="cal">
                {MyCalendar()}
                </Col>
            </Row>
            <Row>
                <Col>
                {displayBookingForm()}
                </Col>
            </Row>
            <Row>
                <Col>
                    <h3>New Bookings</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    {displayNewBookings()}
                </Col>
            </Row>
            <Row>
                <Col> {displayCancelledBookings()}
                </Col>
            </Row>
            {modalevent()}
            
        </Container>
    )
}

export default AdminBooking
