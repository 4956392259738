import React from "react"
import { Container, Row, Col } from "reactstrap"
import "./app.css"
import TileContainer from "../containers/TitleContainer"
import invisiBreak from "../assets/pattern-3.svg"
import MapContainer from "./MapContainer";
import "./visit.css"
const Visit = () => {
    const style = {
        width: '100%',
        height: '80%'
    }
    return (
        <Container fluid>
            <TileContainer title={"Plan Your Visit"} />
            <Row className="row-m-20">
                <Col className="col-m-b-20 remove-padding">
                    <div className="textContainer">
                        We can be found on Stockwell Street in the centre of Leek, just opposite the Market Place and next door to The Foxlowe, our popular local arts venue.

 <br />

We are fully accessible with level access from the street and disabled facilities.
<br />
We welcome dogs!
                </div>
                </Col>
            </Row>
            <Row className="row-m-20">
                <Col className="remove-padding">
                    <div>
                        <img src={invisiBreak} className="center" style={{ width: "100%", height: "50%", borderRadius: 5, opacity: 0.5 }} />
                    </div>
                </Col>
            </Row>
            <Row className="row-m-20">
                <Col md={6} className="col-m-b-20 remove-padding">
                    <div className="textContainer" style={{ marginBottom: 15 }}>
                        {/* 
                        <b>Festive Opening Hours</b><br/>
                        <ul style={{ listStyleType: "none"}}>
                        <li><div className="day">Thursday 24th</div><div className="time">8:00am - 2.30.pm</div> </li>
                        <li><div className="day">Friday 25th</div><div className="time">Closed</div></li>                 
                        <li><div className="day">Saturday 26th</div><div className="time">Closed</div></li>                 
                        <li><div className="day">Sunday 27th</div><div className="time">Closed</div> </li>                 
                        <li><div className="day">Monday 28th</div><div className="time">Closed</div> </li>                 
                        <li><div className="day">Tuesday 29th</div><div className="time">8:00am - 3:00pm</div> </li>         
                        <li><div className="day">Wednesday 30th</div><div className="time">8:00am - 3:00pm</div> </li>        
                        <li><div className="day">Thursday 31st</div><div className="time">8:00am - 3:00pm</div> </li>        
                        <li><div className="day">Friday 1st </div><div className="time">Closed</div> </li>       
                    </ul><br/> */}
                        <b>Opening Hours</b><br />
                        <br />
                    The Tea room is open every day from 8am.<br /><br />
                        <ul style={{ listStyleType: "none" }}>
                            <li><div className="day">Monday   </div><div className="time">8:30am - 2.30pm</div></li>
                            <li><div className="day">Tuesday  </div><div className="time">8:30am - 2.30pm</div></li>
                            <li><div className="day">Wednesday</div><div className="time">8:30am - 2.30pm</div></li>
                            <li><div className="day">Thursday </div><div className="time">8:30am - 2.30pm</div></li>
                            <li><div className="day">Friday   </div><div className="time">8:30am - 2.30pm</div></li>
                            <li><div className="day">Saturday </div><div className="time">8:30am - 2.30pm</div></li>
                            <li><div className="day">Sunday   </div><div className="time">8:30am - 2.30pm</div></li>
                        </ul>
                        <br />
The Kitchen closes 30 minutes prior. <br />
The Sandwich Bar is open every day from 8:30am until 2:30pm.

</div>
                    <div className="textContainer">
                        <b>Sat Nav</b> <br />
For Sat Nav please use the postcode ST13 6DH.  We are situated at the upper end of Stockwell Street opposite the Market Place.
</div>
                </Col>

                <Col md={6}>
                    <div className="textContainer">
                        <b>Parking</b><br />
While we don't have our own car park, we are within very short walking distance (less than 1 minute) from several town centre
car parks.<br />
                        <br />
We would recommend Stockwell Street Car Park or Silk Street car park.  As of April 2018 both are charged at £1.00 per hour between 9:30am  and 3:30pm from Monday to Saturday.  Which means at weekends they are free between 3:30pm on Saturday and 9:30am on Monday.
<br /><br />
While parking is also available on Market Place Car Park this is not available on Saturday due to the towns market. Should you
be arriving on a Friday please don’t park on Market Place. You may find it very difficult to leave on the Saturday once the stalls have been set up! Occasional other events affect the parking on Market Place, please do check any signage before leaving your vehicle there.
<br /><br />


For your information, a parking map is available to download here
</div>
                </Col>
            </Row>
            <Row className="row-m-20">
                <Col className="remove-padding">
                    <div>
                        <img src={invisiBreak} className="center" style={{ width: "100%", height: "50%", borderRadius: 5, opacity: 0.5 }} />
                    </div>
                </Col>
            </Row>
            <Row className="row-m-20">
                <Col className="col-m-b-20 remove-padding">
                    <div className="textContainer">
                        <b>Staying with us</b> <br />
                        <br />
                        Check in is from 2pm, although you are very welcome to arrive earlier if arranged during booking. Check out is by 10am please, although again we can usually offer flexibility on this as long as we are made aware in advance.  Please do let us know your preference when booking so we can make housekeeping arrangements.
                        <br />
                        <br />
                        Should you be arriving before 3:00pm (or 2:30 on Sunday) please go into the Tearoom where you will be welcomed and shown to you room.
                        <br />
                        <br />
                        If you plan to arrive later, we operate a simple code system for your convenience. The codes allow you to gain access to your room at any time.  Before the Tearoom closes for the day we text or email the codes to any guests that have not yet arrived. Do keep hold of your codes!
                        <br />
                        <br />
                        As we don’t live on the premises we have an out of hours contact number <b>(07735226099)</b> clearly displayed by the main entrance. Please don’t hesitate to call us should you require any further information.
                </div>
                </Col>
            </Row>
            <Row className="row-m-20">
                <Col className="remove-padding">
                    <div>
                        <img src={invisiBreak} className="center" style={{ width: "100%", height: "50%", borderRadius: 5, opacity: 0.5 }} />
                    </div>
                </Col>
            </Row>
            <Row className="row-m-20">
                <Col className="col-m-b-20 remove-padding">
                    <div className="textContainer">
                        <b>Room Facilities</b>
                        <ul>
                            <li>All of our rooms have WIFI and full access details can be found on the information sheets in your room.</li>
                            <li>All rooms are en-suite.</li>
                            <li>Superior rooms have superking beds, which can also be configured as twin and a sofa area. They are a little more spacious than standard rooms.</li>
                            <li>Dog friendly rooms are available, we don’t charge for dogs to stay but do suggest you might like to make a donation to our local dog rescue centre.</li>
                            <li>Due to the historic nature of our building all rooms are accessed by a staircase with 11 steps. Rooms 1,2 and 3 are on the first level. Rooms 4 and 5 have a small set of steps and Rooms 6, 7 and 8 are located up further stairs. Please do advice us of any access requirements, we will always do our best to accommodate your requests.</li>
                            <li>We can provide a mini fridge, travel cot on request.</li>
                        </ul>
                    </div>
                </Col>
            </Row>
            <Row className="row-m-20">
                <Col className="remove-padding">
                    <div>
                        <img src={invisiBreak} className="center" style={{ width: "100%", height: "50%", borderRadius: 5, opacity: 0.5 }} />
                    </div>
                </Col>
            </Row>
            <Row className="row-m-20" style={{ height: '70vh', marginBottom: 50 }}>
                <Col className="remove-padding" >
                    <div className="textContainer" style={{ height: '70vh', marginBottom: 50 }}>
                        <h3>Find Us</h3>
                        <p> 1 - 3 Stockwell St, Leek ST13 6DH</p>
                        <MapContainer Style={style} />

                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Visit