import React, {useState, useEffect} from "react"
import {Container, Row, Col} from "reactstrap";
import "./app.css"
import TileContainer from "../containers/TitleContainer"
import invisiline from "../assets/pattern-3.svg"
import menu from "../assets/sandwich-bar-menu-long.pdf"
import ldMenu from "../assets/wh-lockdown-nov.pdf"
import {firestore} from "../firebase";
import sandwichMenu from "../assets/SandwichBarMenu.pdf"


const FoodToGo = () => {
    const [lockdownArray, setLockdownArray] = useState(false)


    useEffect(() => {
        const fetchData = async() => {
            const lockdown = firestore.collection('lockdown')
            const doc2 = await lockdown.get().then((snapshot2)=> {
               const data2 = snapshot2.docs.map((doc2) => ({
                 id: doc2.id,
                 ...doc2.data(),
               }));
               console.log(data2[0].lockdown)
               setLockdownArray(data2[0].lockdown)
             })
        }
    
        fetchData();
    
        },[])


        const displayMenu = () => {   
            if(lockdownArray){
                console.log("hit true")
                    return(
                        <a href={menu} target="_blank">
                        <div className="textContainer" style={{textAlign:"center", fontSize:"140%", color:"#83868b"}}>
                            <img src={invisiline}></img>
                            Sandwich Bar Menu
                            <img src={invisiline}></img>
                        </div>
                        </a>
                    )
            }
            else{
                console.log("hit false")
                return(
                <a href={menu} target="_blank">
                <div className="textContainer" style={{textAlign:"center", fontSize:"140%", color:"#83868b"}}>
                    <img src={invisiline}></img>
                    Sandwich Bar Menu
                    <img src={invisiline}></img>
                </div>
                </a>
                )
            }
        }

    return(
        <Container fluid>
        <TileContainer title={"Food To Go"}/>
        <Row className="row-m-20">
  <iframe src={sandwichMenu + "#toolbar=0&navpanes=0"} type="application/pdf" frameborder="0" width="100%" height="900"></iframe>

        </Row>
</Container>
    )
}

export default FoodToGo
