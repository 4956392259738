import React from "react"
import {Container, Row, Col} from "reactstrap";
import "../app.css"
import TitleContainer from "../../containers/TitleContainer"
import { auth, signInWithGoogle, generateUserDocument } from "../../firebase";
import { Link, navigate } from "@reach/router";

const AdminLinks = () => {
    const signOut =() => {
        auth.signOut();
        navigate("/")
      }
    return(
        <div>
            <Link to="admin" style={{color:"black"}}>Admin</Link>
            <Link onClick={signOut} to="/" style={{color:"black", marginLeft:10}}>Sign Out</Link>
        </div>
    )
}

export default AdminLinks
