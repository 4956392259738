import React, { useState } from "react";
import {Form, Input, Button, Label} from "reactstrap"
import { auth, generateUserDocument } from "../../firebase";
import "../app.css"
import TitleContainer from "../../containers/TitleContainer"

const AdminCreateUser = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [displayName, setDisplayName] = useState("");
    const [userType, setUserType] = useState("");
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const createUserWithEmailAndPasswordHandler = async (event, email, password) => {
      event.preventDefault();
      try{
        const {user} = await auth.createUserWithEmailAndPassword(email, password);
        generateUserDocument(user, {displayName, userType});
        setSuccess(true)
      }
      catch(error){
        setError('Error Signing up with email and password');
      }
  
      setEmail("");
      setPassword("");
      setDisplayName("");
      setUserType("")
    };
    const onChangeHandler = event => {
      const { name, value } = event.currentTarget;
      if (name === "userEmail") {
        setEmail(value);
      } else if (name === "userPassword") {
        setPassword(value);
      } else if (name === "displayName") {
        setDisplayName(value);
      } else if (name === "userType") {
        setUserType(value);
      }
    };

    const displaySuccess = () => {
      if(success) {
        return(
          <div>Your user was created successfully!</div>
        )
      }
      else if(error){
        return(
        <div>There was an error creating your user, error reads: {error} <br/> Contact Matt and let him know the error message </div>
        )
      }
    }
    return (
      <div className="mt-8">
         <TitleContainer title={"Create Admin User"}/>
        <div style={{marginBottom:40}} className="border border-blue-400 mx-auto w-11/12 md:w-2/4 rounded py-8 px-4 md:px-8">
          {error !== null && (
            <div className="py-4 bg-red-600 w-full text-white text-center mb-3">
              {error}
            </div>
          )}
          {displaySuccess()}
          <Form className="">
            <Label htmlFor="displayName" className="block">
              Display Name:
            </Label>
            <Input
              type="text"
              className="my-1 p-1 w-full "
              name="displayName"
              value={displayName}
              placeholder="E.g: Faruq"
              id="displayName"
              onChange={event => onChangeHandler(event)}
            />
            <Label htmlFor="userEmail" className="block">
              Email:
            </Label>
            <Input
              type="email"
              className="my-1 p-1 w-full"
              name="userEmail"
              value={email}
              placeholder="E.g: faruq123@gmail.com"
              id="userEmail"
              onChange={event => onChangeHandler(event)}
            />
            <Label htmlFor="userPassword" className="block">
              Password:
            </Label>
            <Input
              type="password"
              className="mt-1 mb-3 p-1 w-full"
              name="userPassword"
              value={password}
              placeholder="Your Password"
              id="userPassword"
              onChange={event => onChangeHandler(event)}
            />
            <Label htmlFor="userType" className="block">User Type:</Label>
            <Input
              type="select"
              name="userType"
              onChange={event => onChangeHandler(event)}>
                <option value=""></option>
                <option value="readOnly">Read Only</option>
                <option value="readWrite">Read and Write</option>
            </Input>
            <Button style={{marginBottom:40, marginTop:20}}
              className="bg-green-400 hover:bg-green-500 w-full py-2 text-white"
              
              onClick={event => {
                createUserWithEmailAndPasswordHandler(event, email, password);
              }}
            >
              Sign up
            </Button>
          </Form>
        </div>
      </div>
    )
}

export default AdminCreateUser
