import React, {Component} from "react";
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
 
export class MapContainer extends Component {
  render() {
    const style = {
      width: '80%',
      height: '70%'
    }
    return (
      <Map google={this.props.google} style={style} zoom={14}
      initialCenter={{
        lat: 53.106887652907886,
        lng: -2.0249608882977395
    }}
      >
 
        <Marker onClick={this.onMarkerClick}
                name={'Current location'} />
 
        <InfoWindow onClose={this.onInfoWindowClose}>
            <div>
              <h1>Whitehart Tea Room</h1>
            </div>
        </InfoWindow>
      </Map>
    );
  }
}
 
export default GoogleApiWrapper({
  apiKey: ("AIzaSyAyAo2egxgRYTq8I5ZYZVO85QH7q8LtnlE")
})(MapContainer)