import React, { useState } from "react"
import { Container, Row, Col, Form, Label, FormGroup, Input, Button } from "reactstrap"
import "./app.css"
import doggo from "../assets/IMG_3606.JPG"
import breakline from "../assets/break.PNG";
import invisiBreak from "../assets/pattern-3.svg"
import modernTeaRoom from "../assets/modernTeaRoom.JPG"
import TileContainer from "../containers/TitleContainer"
import { firestore } from "../firebase";
import moment from "moment";

const BookingForm = (props) => {

    const [title, setTitle] = useState();
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [tel, setTel] = useState("");
    const [email, setEmail] = useState();
    const [address, setAddress] = useState();
    const [postcode, setPostcode] = useState();
    const [additionalAdults, setAdditionalAdults] = useState(0);
    const [additionalChildren, setAdditionalChild] = useState(0);
    const [singleOccupancy, setSingleOccupancy] = useState(false);
    const [travelCotRequired, setTravelCotRequired] = useState(false)
    const [successfullyBooked, setSuccessfullyBooked] = useState(false)
    const [additionalInfo, setAdditionalInfo] = useState("")

    const onChangeHandler = (event) => {
        const { name, value } = event.currentTarget;

        if (name === 'title') {
            setTitle(value);
        }
        else if (name === "firstName") {
            setFirstName(value);
        }
        else if (name === "lastName") {
            setLastName(value);
        }
        else if (name === "tel") {
            setTel(value);
        }
        else if (name === "email") {
            setEmail(value);
        }
        else if (name === "address") {
            setAddress(value)
        }
        else if (name === "postcode") {
            setPostcode(value)
        }
        else if (name === "additionalAdults")
        {
            setAdditionalAdults(value)
        }
        else if (name === "additionalChildren"){
            setAdditionalChild(value)
        }
        else if (name === "travelCotRequired"){
            setTravelCotRequired(value)
        }
        else if (name === "additionalInfo"){
            setAdditionalInfo(value)
        }
    }

    const getDateWithoutTime = (dateWithTime) => {
        return moment(new Date(dateWithTime)).format("Do MMMM YYYY");
        //new Date(dateWithTime.getFullYear(), dateWithTime.getMonth(), dateWithTime.getDate())

    }

    const createBooking = () => {
        if(tel.length < 1){
            alert("Please enter your mobile number")
            return;
        }
        var roomCollection = props.location.state.room.replace(/ /g, '').toLowerCase()
        console.log(roomCollection)
        firestore.collection("tempBooking" + roomCollection).add({
            RoomName: roomCollection,
            bookedFrom: props.location.state.bookFrom,
            numberOfNights: props.location.state.numberOfNights,
            bringingDogs: props.location.state.bringingDogs,
            singleOccupancy: props.location.state.singleOccupancy,
            title: title,
            firstName: firstName,
            lastName: lastName,
            tel: tel,
            email: email,
            address: address,
            postcode: postcode,
            additionalAdults: additionalAdults,
            additionalChildren: additionalChildren,
            travelCotRequired: travelCotRequired,
            singleOccupancy: singleOccupancy,
            createDateTime: new Date(),
            additionalInfo: additionalInfo
        }).then(() => {
            setSuccessfullyBooked(true)
        });
    }

    const getDogsAsYesNo = (e) => {
        if(e)
        {
            return "Yes"
        }
        else{
            return "No"
        }
    }

    const displayBookingForm = () => {
        if(successfullyBooked)
        {
            return(
                <Row className="row-m-20">
                    <Col className="col-m-b-20 remove-padding">
                    <div className="textContainer">
                    Thank you for your booking enquiry. We will be in touch within 24 hours to confirm your booking and to complete payment.
                        </div>
                    </Col>
                </Row>
            )
            
        }else{
            return(
                <div>
                    <Row className="row-m-20">
                <Col className="textContainer">
                    <p>Please fill in the below booking form. We will then contact you via telephone to complete the booking and take payment</p>
                </Col>
            </Row>
            <Row className="row-m-20">
                <Col className="col-m-b-20 remove-padding">
                    <div className="textContainer">
                        <Form>
                            <Row>
                            <Col>Please ensure that all fields are filled</Col>
                            </Row>
                            <Row form className="row-m-20">
                                <Col md={3}>
                                    <Label for="checkInDate">Check In Date</Label>
                                    <Input type="text" name="checkInDate" id="checkInDate" disabled={true} value={getDateWithoutTime(props.location.state.bookFrom)} />
                                </Col>
                                <Col md={3}>
                                    <Label for="nights">Number Of Nights</Label>
                                    <Input type="text" id="nights" name="nights" disabled={true} value={props.location.state.numberOfNights} />
                                </Col>
                                <Col md={3}>
                                    <Label for="dogs">Dogs</Label>
                                    <Input type="text" id="dogs" name="dogs" disabled={true} value={getDogsAsYesNo(props.location.state.bringingDogs)} />
                                </Col>
                                <Col md={3}>
                                    <Label for="room">Room</Label>
                                    <Input type="text" id="room" name="room" disabled={true} value={props.location.state.room} />
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="additionalAdults">Additional Adults</Label>
                                        <Input onChange={(event) => onChangeHandler(event)} type="select" name="additionalAdults" id="additionalAdults">
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="additionalChildren">Additional Children</Label>
                                        <Input onChange={(event) => onChangeHandler(event)} type="select" name="additionalChildren" id="additionalChildren">
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="travelCotRequired">Travelcot Required</Label>
                                        <Input onChange={(event) => onChangeHandler(event)} type="select" name="travelCotRequired" id="travelCotRequired">
                                            <option value="false">No</option>
                                            <option value="true">Yes</option>
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="title">Title</Label>
                                        <Input onChange={(event) => onChangeHandler(event)} type="select" name="title" id="title">
                                            <option value=""></option>
                                            <option value="Mr">Mr</option>
                                            <option value="Mrs">Mrs</option>
                                            <option value="Miss">Miss</option>
                                            <option value="Ms">Ms</option>
                                            <option value="Mx">Mx</option>
                                            <opion value="Other">Other</opion>
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="firstName">First Name</Label>
                                        <Input onChange={(event) => onChangeHandler(event)} type="text" name="firstName" id="firstName" />
                                    </FormGroup>
                                </Col>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="lastName">Last Name</Label>
                                        <Input onChange={(event) => onChangeHandler(event)} type="text" name="lastName" id="lastName" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="tel">Mobile Number</Label>
                                        <Input onChange={(event) => onChangeHandler(event)} type="text" name="tel" id="tel" />
                                    </FormGroup>
                                </Col>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="email">Email</Label>
                                        <Input onChange={(event) => onChangeHandler(event)} type="text" name="email" id="email" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="address">Address</Label>
                                        <Input onChange={(event) => onChangeHandler(event)} type="textarea" rows={6} name="address" id="address" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={3}>
                                    <FormGroup>
                                        <Label for="postcode">Postcode</Label>
                                        <Input onChange={(event) => onChangeHandler(event)} type="text" name="postcode" id="postcode" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col md={6}>
                                    <FormGroup>
                                        <Label for="additionalInfo">Additional Information</Label>
                                        <Input type="textarea" name="additionalInfo" rows={5} onChange={(event) => onChangeHandler(event)}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form className="row-m-20">
                                <Col>
                                    <FormGroup>
                                        <Button onClick={(e) => createBooking(e)}>Book Now</Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                                    <Col>Please ensure that all fields are filled</Col>

                    </div>
                </Col>
            </Row>
                </div>
            )
        }
    }

    return (

        <Container fluid>

            <Container fluid>
                <TileContainer title={"Booking Form"} />
            </Container>
            <div>
                {displayBookingForm()}
            </div>
        </Container>
    )
}

export default BookingForm