import React, { useState } from 'react';
import { Link } from "@reach/router"
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import './nav.css'

const Navbarwh = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
 
  const mtoggle = () => {
    if (isOpen) {
      setIsOpen(!isOpen)
    }
  }

    return (
    <div id="home">
      <Navbar expand="md" style={{ borderRadius: 5, backgroundColor: "#83868b" }} light className="whFont">
        <NavbarBrand href="/">White Hart Tea Room</NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} style={{ zIndex: 999 }} navbar>
          <Nav style={{ zIndex: 999 }} className="mr-auto" navbar>
            <NavItem>
              <NavLink onClick={mtoggle} tag={Link} to="/tearoom">Tearoom</NavLink>
            </NavItem>
            <NavItem>
              <NavLink onClick={mtoggle} tag={Link} to="/accommodation">Accommodation</NavLink>
            </NavItem>
            <NavItem>
              <NavLink  onClick={mtoggle} tag={Link} to="/foodtogo">Food To Go</NavLink>
            </NavItem>
            <NavItem>
              <NavLink  onClick={mtoggle} tag={Link} to="/visit">Plan Your Visit</NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink  onClick={mtoggle} tag={Link} to="/events">Events</NavLink>
            </NavItem> */}
            <NavItem>
              <NavLink  onClick={mtoggle} tag={Link} to="/contact">Contact Us</NavLink>
            </NavItem>
            <NavItem>
              <NavLink  onClick={mtoggle} tag={Link} to="/gallery">Gallery</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default Navbarwh;