import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "reactstrap"
import "./app.css"
import TileContainer from "../containers/TitleContainer"
import { firestore } from "../firebase"
import moment from "moment"
import { Spinner } from "reactstrap"
import invisiBreak from "../assets/pattern-3.svg"
import party from "../assets/party.jpg"
import noImage from "../assets/noImage.jpg"
import eventsLeaflet from "../assets/events.pdf"

const Events = () => {
    const [loadingEvents, setLoadevents] = useState(true);
    const [eventsArray, setEventsArray] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const events = firestore.collection('events')
            const doc = await events.where('eventDate', '>', new Date()).orderBy('eventDate', 'desc').limit(10).get().then((snapshot) => {
                const data = snapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setEventsArray(data)
            }).then((snapshot) => {
                setLoadevents(false)
            });


        }

        fetchData();

    }, [])

    const eventImage = (doc) => {
        if(typeof doc.imageUrl !== "undefined"){
            return(
                <Col md={3} style={{marginRight:10}}>            
                <img src={doc.imageUrl} style={{maxHeight:220}}/>
                </Col>
            )
        }
        else{
            return(
                <Col md={3} style={{marginRight:10}}>
                <img src={noImage} className="shadow-image" style={{maxHeight:220, marginLeft:10, borderRadius:10}}/>
                </Col>
            )
        }
    }
    const displayEvents = () => {
        if (loadingEvents) {
            return (
                <div style={{ textAlign: "center" }}>
                    loading events...<br /><br />
                    <Spinner />
                </div>
            )
        }
        else {
            return eventsArray.map((doc, id) =>
                <div>
                     <div className="textContainer" style={{ marginBottom: 20 }}>
                    <Row className="row-m-20">
                            {eventImage(doc)}
                            <Col md={2}>
                                <div>
                                    <b>{doc.eventTitle}</b>
                                </div>
                            </Col>
                            <Col md={2}>
                                <b>Date:</b> {moment(doc.eventDate.seconds * 1000).format('DD-MM-YYYY')}
                            </Col>
                            <Col md={3}>
                                {doc.eventDescription}
                            </Col>
                            
                        
                    </Row>
                    </div>
                    <Row className="row-m-20">
                        <Col className="remove-padding">
                            <div>
                                <img src={invisiBreak} className="center" style={{ width: "100%", height: "50%", borderRadius: 5, opacity: 0.5 }} />
                            </div>
                        </Col>
                    </Row>

                </div>
            )
        }
    }
    return (
        <Container fluid>
            <TileContainer title={"Events"} />
            <Row className="row-m-20">
                <Col md={6}>
                    <div style={{paddingBottom: 10 }}>
                        <img className="image-wh shadow-image" src={party} />
                    </div>
                </Col>
                <Col className="col-m-b-20 remove-padding" md={6}>
                    <div className="textContainer">
                        The Tearoom and Courtyard can be hired FREE of charge out of hours!<br />
                        <br />


                        Birthdays, hen parties, baby showers, any occasion can be celebrated in our lovely surroundings.<br /><br />



                        Menus can be anything from simple buffets, or Afternoon Tea to full sit down meals. We work with you to deliver just the celebration you have in mind.<br />

                        We are fully licensed for alcohol and music!<br />



                        Special rates are available for block<br />

                        bookings of our B&B rooms ( We have 8 rooms, sleeping up to 20 adults plus 4 children in child size beds) and tearoom.<br />

                        Call us to discuss or drop us a line. Further information is available in our broucher <a href={eventsLeaflet}>HERE</a><br />
                </div>
                </Col>
            </Row>
            <Row className="row-m-20">
                <Col className="remove-padding">
                    <div>
                        <img src={invisiBreak} className="center" style={{ width: "100%", height: "50%", borderRadius: 5, opacity: 0.5}} />
                    </div>
                </Col>
            </Row>
            <TileContainer title={"Upcoming Events"} />
            {displayEvents()}
        </Container>
    )
}

export default Events
