import React from 'react';
import {Container, Row, Col} from 'reactstrap';
import './app.css';
import facebook from '../assets/f_logo_RGB-Grey_58.png'
import './app.css'
const Footer = () => {
    return(
        <Container style={{minHeight:90, backgroundColor:"#83868b", borderTopLeftRadius:5, borderTopRightRadius:5}}>
            <Row>
                <Col md={4} className="footerColumn">
                    Location:
                    <ul style={{listStyleType:"none"}}>
                        <li>1-3 Stockwell Street,</li>
                        <li>Leek</li>
                        <li>Staffordshire</li>
                        <li>ST13 6DH</li>
                    </ul>
                </Col>
                <Col md={4} className="footerColumn">
                    Follow Us:<br/>
                    <ul style={{listStyleType:"none"}}>
                    <li><a href="https://www.facebook.com/whitehartleek" target="_blank">
                    <img src={facebook} style={{maxWidth:50}}/>
                    </a></li>
                    </ul>
                </Col>
                <Col md={4} className="footerColumn">
                    Interact<br/>
                    <ul style={{listStyleType:"none"}}>
                    <li><a style={{ textDecoration: 'none' }} className="nav-link" href="contact">Contact Us</a></li>
                    </ul>
                </Col>
            </Row>
        </Container>
    )
}

export default Footer