  
import React, { useContext, useEffect, useState } from "react";
import { Router, Link } from "@reach/router";
import {Row, Col, Container, Collapse } from "reactstrap"
import Home from "./Home";
import Tearoom from "./Tearoom"
import Accommodation from "./Accommodation"
import FoodToGo from "./FoodToGo"
import Visit from "./Visit"
import Events from "./Events"
import SignIn from "./SignIn"
import SignUp from "./SignUp"
import ContactUs from "./ContactUs"
import { UserContext } from "../providers/UserProvider";
import Footer from "./Footer.js";
import Header from "./Header.js";
import Gallery from "./Gallery.js";
import Admin from "./Admin/Admin.js";
import AdminBookings from "./Admin/AdminBookings.js";
import AdminEvent from "./Admin/AdminEvent.js";
import AdminPromotion from "./Admin/AdminPromotion.js";
import AdminLinks from "./Admin/AdminLinks.js";
import AdminCreateUser from "./Admin/AdminCreateUser.js";
import BookingForm from "./BookingForm.js"
import {firestore} from "../firebase";
import './app.css'
import closeLine from "../assets/close-line.svg";

function Application() {
  const user = useContext(UserContext);
  const [loadingPromos, setLoadPromos] = useState(true);
  const [promosArray, setPromosArray] = useState([]);
  const [visible, setVisible] = useState(true);

  const onDismiss = () => setVisible(false);
  useEffect(() => {

    const fetchPromoData = async() => {
        const events =  firestore.collection('promotions')
        const doc = await events.where("rowStatus","==", true).orderBy('startDate', 'desc').limit(1).get().then((snapshot) => {
            const data = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            setPromosArray(data)
          }).then((snapshot) =>{
            setLoadPromos(false)
          });
     
            
    } 
    fetchPromoData();
    },[])

    const displayEventContent = () => {
      if(!loadingPromos){
          return promosArray.map((doc, id) => 
          <Row style={{marginTop:10}}>
            <Col md={{size:6, offset:3}} className="remove-padding">
          <div>
                            <Collapse color="" isOpen={visible} className="textContainer">
              {doc.promotionText} See Event <Link to="events" style={{color:"black"}}>HERE</Link> <img src={closeLine} onClick={onDismiss} style={{maxHeight:20,paddingRight:10,paddingTop:5 , float:"right"}}/>
              </Collapse>
          </div>
          </Col>
      
          </Row>)
      }
  }
  return (
    
        user ?
        <Container>
          <AdminLinks user={user}/>
          {displayEventContent()}
          <Header user={user}/>
        <Router  primary={false} >
        <Home path="/" />
        <Tearoom path="/tearoom"/>
        <Accommodation path = "/accommodation"/>
        <FoodToGo path = "/foodtogo"/>
        <Visit path="/visit"/>
        <Events path="/events"/>
        <SignUp path="/signUp"/>
        <SignIn path="/signIn"/>
        <ContactUs path="/contact"/>
        <Gallery path = "/gallery"/>
        <BookingForm path= "/bookingForm"/>
        <Admin user={user} path = "/admin"/>
        <AdminBookings user={user} path="/admin-bookings"/>
        <AdminEvent user={user} path="/admin-events"/>
        <AdminPromotion user={user} path="/admin-promotions"/>
        <AdminCreateUser user={user} path="/admin-createUser"/>
        </Router>
        <Footer/>
        </Container>
      :
      <Container>
      {displayEventContent()}
      <Header/>
        <Router  primary={false}>
            <Home path="/" />
            <Tearoom path="/tearoom"/>
            <Accommodation path = "/accommodation"/>
            <FoodToGo path = "/foodtogo"/>
            <Visit path="/visit"/>
            <Events path="/events"/>
            <SignIn path="/signIn"/>
            <ContactUs path="/contact"/>
            <Gallery path = "/gallery"/>
            <BookingForm path= "/bookingForm"/>
        </Router>
        <Footer/>
        </Container>
      
  );
}

export default Application;