import React from "react"
import {Jumbotron} from "reactstrap"
import NavbarWh from "./NavbarWh"
import logoPrimary from "../assets/primary white 400px.png";
import teaRoom from "../assets/teaRoom.jpg";
import '../css/header.css'
const Header = (props) => {
    return(
    <Jumbotron className="div-with-image-background jumbo-wh" style={{backgroundImage:"url(" + teaRoom + ")" }}>
    <img src={logoPrimary} className="center jumbo-image"/>
    <div className="nav-cont-div">
    <NavbarWh user={props.user}/>
    </div>
    </Jumbotron>
    )
}

export default Header